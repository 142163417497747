// Solana.js
import React, { useMemo } from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { CoinbaseWalletAdapter, MathWalletAdapter, PhantomWalletAdapter, SolflareWalletAdapter, TrustWalletAdapter } from '@solana/wallet-adapter-wallets';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { SolanaProvider } from './SolanaContext';

require('@solana/wallet-adapter-react-ui/styles.css');

const network = WalletAdapterNetwork.Mainnet;
const ALCHEMY_KEY = process.env.REACT_APP_ALCHEMY_KEY;
const endpoint = `https://solana-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`;

const Solana = ({ children }) => {
  const wallets = useMemo(() => [new TrustWalletAdapter(), new PhantomWalletAdapter(), new SolflareWalletAdapter(), new CoinbaseWalletAdapter(), new MathWalletAdapter() ], [network]);
  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <SolanaProvider endpoint={endpoint}>
            {children}
          </SolanaProvider>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default Solana;
