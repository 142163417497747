function Footer() {
    return (
        <footer className="cx-footer">
            <ul className="cx-fotter-options">
                <li className="cx-footer-item"><a href="#">Contact us</a></li>
                <li className="cx-footer-item"><a href="#">FAQ</a></li>
                <li className="cx-footer-item"><a href="#">Supported coins</a></li>
                <li className="cx-footer-item"><a href="#">Supported Wallets</a></li>
            </ul>
        </footer>
    );
}

export default Footer;