export const extractAddress = (address) => {
  if (!address) return "";
  return address.substring(0, 4) + "..." + address.substring(address.length - 6);
}

export const countResult = (num) => {
  if (!num) { return false; }
  return Math.pow(10, num - 1);
}

// export const numberToHex = (number, decimals) => {
//   const bigNumber = BigInt(Math.round(number * Math.pow(10, decimals)));
//   return '0x' + bigNumber.toString(16);
// }

// export const hexToNumber = (hex, decimals) => {
//   const bigNumber = BigInt(hex);
//   return Number(bigNumber) / Math.pow(10, decimals);
// }