import {
	Route,
	createBrowserRouter,
	createRoutesFromElements,
} from "react-router-dom";
import Layaut from "./Pages/Layaut";
import Home from "./Pages/Home";

const router = () => {
	return createBrowserRouter(
		createRoutesFromElements(
			<Route path="/" element={<Layaut />}>
				<Route
					path=":token"
					element={<Home />}
				/>
			</Route>
		),
	);
};

export { router };
