import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { RouterProvider } from "react-router-dom"
import { router } from "./router";
import BlackListComponent from './Components/BlackList/BlackListComponent';

const App = () => {
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";
  return (
    // <GoogleOAuthProvider
    //   clientId={googleClientId}
    // >
    //   <BlackListComponent />
    //   <div className="app-wrapper">
    //     <RouterProvider router={router()} />
    //   </div>
    // </GoogleOAuthProvider>
    <div>
      <BlackListComponent />
      <div className="app-wrapper">
        <RouterProvider router={router()} />
      </div>
    </div>
  );
};

export default App;
