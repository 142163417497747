// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './css/styles.css';
// import { Provider } from 'react-redux';
// import { BrowserRouter } from "react-router-dom";
// import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
// import reducer from './Store/Reducers';
// import App from './App';

// const root = ReactDOM.createRoot(document.getElementById('root'));

// const store = configureStore({
//   reducer,
//   middleware: getDefaultMiddleware()
// })

// root.render(
//   <Provider store={store}>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </Provider>
// );



import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import './css/styles.css';
import store from "./Store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
