import React, { useState, useEffect } from 'react'
import { BiTimer } from "react-icons/bi"
import { ClipLoader } from "react-spinners"

const CountdownTimer = ({ initialSeconds = 30 }) => {
  const [remainingSeconds, setRemainingSeconds] = useState(initialSeconds);
  const [isRed, setIsRed] = useState(false);

  useEffect(() => {
    if (remainingSeconds <= 5) {
      setIsRed(true);
    } else {
      setIsRed(false);
    }
  }, [remainingSeconds])

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const formattedTime = formatTime(remainingSeconds);

  useEffect(() => {
    if (remainingSeconds > 0) {
      const timer = setInterval(() => {
        setRemainingSeconds((prevSeconds) => {
          if (prevSeconds <= 0) {
            clearInterval(timer);
            return 0;
          }
          return prevSeconds - 1;
        });
      }, 1000);

      return () => clearInterval(timer); // Cleanup the timer on unmount
    }
  }, [remainingSeconds]);

  return !remainingSeconds ?
    <div className="position-absolute d-flex">
      <ClipLoader size={15} color={"#DC3545"} />
    </div >
    : <div className='d-flex align-items-center'>
      <BiTimer size={20} className='me-1' color={isRed ? "#DC3545" : ""} />
      <span
        style={{ fontWeight: "400", fontSize: "14px" }}
        className={`${isRed ? "text-danger" : ""}`}>
        <b>{formattedTime}</b>
      </span>
    </div>

}

export default CountdownTimer;
