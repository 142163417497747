import React, { useEffect } from 'react'
import { Accordion, Button } from 'react-bootstrap'
import cryllexSvg from "./../../../../assets/images/crillex.svg"
import { useAccount } from 'wagmi';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import WalletConnectWalletInfo from '../WalletInfo/WalletConnectWalletInfo';
import { extractAddress } from '../helper';
import { disconnect } from '@wagmi/core';
import { useWagmiConfig } from '../../../../WalletConnectContext';

export default function WalletConnectWalletWalletConfig({
  isLoading,
  selectedAccordionKeys,
  setSelectedAccordionKeys,
  amount,
  allBalance,
  selectedChain,
  selectedToken,
  setAddress
}) {

  const config = useWagmiConfig();
  const { open: openModal, close: closeModal, } = useWeb3Modal()
  const { address, isConnected } = useAccount();

  useEffect(() => {
    if (address) {
      setAddress(address);
    }
  }, [address])

  const _disconnect = async () => {
    await disconnect(config)
  }

  return (
    <Accordion.Item
      eventKey={2}
    // className={!selectedChain ? "pointer-events-none" : ""}
    >
      <Accordion.Header
        className={isLoading ? "disabled-accordion" : ""}
        onClick={() => {
          if (selectedAccordionKeys.find(key => +key === 1)) {
            setSelectedAccordionKeys(selectedAccordionKeys.filter(key => +key !== 1))
          } else {
            setSelectedAccordionKeys([...selectedAccordionKeys, 1])
          }
        }}
      >
        <img src={cryllexSvg} alt="/" />
        {
          address ? `Wallet address ${extractAddress(address)}` : " Connect your wallet"
        }

      </Accordion.Header>
      <Accordion.Body>
        <div className="bg-white shadow p-3 text-dark" style={{ borderRadius: "10px" }}>
          {
            !address ?
              <div>
                <Button variant="primary w-100 shadow" onClick={() => openModal()}>Connect Wallet</Button>
              </div> :
              <div>
                {/* <w3m-button balance loadingLabel /> */}
                <WalletConnectWalletInfo
                  amount={amount}
                  allBalance={allBalance}
                  selectedChain={selectedChain}
                  selectedToken={selectedToken}
                />
                <hr />
                <div className="d-flex justify-content-end">
                  <Button variant="danger" onClick={_disconnect}>Disconnect Wallet</Button>
                </div>
              </div>
          }
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}
