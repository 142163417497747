import React from 'react'
import { Accordion, Button } from 'react-bootstrap'
import cryllexSvg from "./../../../../assets/images/crillex.svg"
import { extractAddress } from '../helper'
import StellarWalletInfo from '../WalletInfo/StellarWalletInfo'

export default function StellarWalletConfig({
  selectedAccordionKeys,
  setSelectedAccordionKeys,
  isLoading,
  address = "",
  allBalance,
  setAddress,
  selectedToken,
  selectedChain,
  amount,
  _disconnect
}) {

  return (
    <Accordion.Item
      eventKey={2}
    // className={!selectedChain ? "pointer-events-none" : ""}
    >
      <Accordion.Header
        className={isLoading ? "disabled-accordion" : ""}
        onClick={() => {
          if (selectedAccordionKeys.find(key => +key === 1)) {
            setSelectedAccordionKeys(selectedAccordionKeys.filter(key => +key !== 1))
          } else {
            setSelectedAccordionKeys([...selectedAccordionKeys, 1])
          }
        }}
      >
        <img src={cryllexSvg} alt="/" />
        {
          address ? `Wallet address ${extractAddress(address)}` : " Connect your wallet"
        }

      </Accordion.Header>
      <Accordion.Body>
        <div className="bg-white shadow p-3 text-dark" style={{ borderRadius: "10px" }}>
          {
            !address ?
              <div>
                <Button variant="primary w-100 shadow">Connect Your Stellar Wallet</Button>
              </div> :
              <div>
                <StellarWalletInfo
                  amount={amount}
                  allBalance={allBalance}
                  selectedChain={selectedChain}
                  selectedToken={selectedToken}
                />
                <hr />
                <div className="d-flex justify-content-end">
                  <Button variant="danger" onClick={_disconnect}>Disconnect Wallet</Button>
                </div>
              </div>
          }
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}
