import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import WalletConnectComponent from './../WalletConnectComponent';
import uuid from 'react-uuid';
import * as signalR from '@microsoft/signalr'
import ApiService from './../Services/ApiService';
import AlertService from './../Services/AlertService';
import { arbitrum, avalanche, base, bsc, mainnet, optimism, polygon } from "wagmi/chains"
import Main from './../modules/main/Main';
import { AVALANCHE_PLATFORM_TYPE, ETHEREUM_PLATFORM_TYPE, SOLANA_PLATFORM_TYPE, TRON_PLATFORM_TYPE } from './../Constants/MainKeys';
import { addPageSpinner, removePageSpinner } from './../Store/Reducers/spinnersReducer';
import { setToken } from './../Store/Reducers/mainReducer';
import SolanaComponent from './../SolanaComponent';
import TronComponent from './../TronComponent';
import { useParams } from 'react-router-dom';
import AuthModal from '../Components/Auth/AuthModal';
import VeriffModal from '../Components/Veriff/VeriffModal';

export default function Home() {

  const { token } = useParams();

  const API_URL_KEY = process.env.REACT_APP_API_URL_KEY || "";

  const dispatch = useDispatch();
  const { user, userToken } = useSelector(state => state.user);
  const [globalConnection, setGlobalConnection] = useState(null);
  const [cryllexChains, setCryllexChains] = useState([]);
  const [walletConnectChains, setWalletConnectChains] = useState([]);
  const [selectedChain, setSelectedChain] = useState(null);
  const [selectedToken, setSelectedToken] = useState(null);
  const [countries, setCountries] = useState([]);

  const wagmiChanis = [arbitrum, avalanche, base, bsc, mainnet, optimism, polygon];

  useEffect(() => {
    getCountries();
    return () => {
      setCountries([]);
    }
  }, [])

  const getCountries = () => {
    ApiService.getCountries().then(response => {
      if (response && response.data) {
        setCountries(response.data);
      }
    }).catch(error => AlertService.alert("error", error));
  }


  useEffect(() => {
    if (cryllexChains && cryllexChains.length && wagmiChanis && wagmiChanis.length) {
      let _chains = [];
      cryllexChains.forEach(item => {
        wagmiChanis.forEach(_item => {
          if (item.chainId === _item.id) {
            _item.config = item;
            _chains.push(_item);
          }
        })
      })
      setWalletConnectChains(_chains);
    }

  }, [cryllexChains])

  useEffect(() => {
    getChains();
  }, []);

  const getChains = () => {
    const spinnerId = uuid();
    dispatch(addPageSpinner(spinnerId));
    ApiService.getChains().then(response => {
      if (response && response.data && response.data.data) {
        setCryllexChains(response.data.data)
      }
    }).catch(error => AlertService.alert("error", error)).finally(() => {
      dispatch(removePageSpinner(spinnerId));
    })
  }

  useEffect(() => {
    localStorage.clear();
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    }
    if (userToken) {
      localStorage.setItem("userToken", userToken);
    }
    if (token) {
      dispatch(setToken(token));
      signal_start();
    }
    return () => {
      localStorage.clear();
      if (user) {
        localStorage.setItem("user", JSON.stringify(user));
      }
      if (userToken) {
        localStorage.setItem("userToken", userToken);
      }
      sessionStorage.clear();
      deleteAllCookies();
    }
  }, []);

  const deleteAllCookies = () => {
    document.cookie.split(';').forEach(cookie => {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    });
  }

  const signal_start = async () => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${API_URL_KEY}/paymenthub`)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.None)
      .build();
    connection.serverTimeoutInMilliseconds = 1000 * 60 * 10;
    try {
      await connection.start();
      await signal_init(connection);
      await signal_update(connection);
      console.assert(connection.state === signalR.HubConnectionState.Connected);
      console.log("SignalR Connected.");
      setGlobalConnection(connection);
      connection.onclose(() => {
        setTimeout(() => signal_start(), 1000);
      });
    } catch (err) {
      console.assert(connection.state === signalR.HubConnectionState.Disconnected);
      setTimeout(() => signal_start(), 1000);
    }
  };

  const signal_init = async (connection) => {
    try {
      await connection.invoke("init", token);
    } catch (err) {
      setTimeout(() => { signal_init(); }, 1000);
    }
  }

  const signal_update = async (connection) => {
    try {
      await connection.invoke("update", token);
    } catch (err) {
      setTimeout(() => { signal_update(); }, 1000);
    }
  }

  const setMainContent = (chains = []) => {
    if (!user) { return false; }
    return <Main
      chains={chains}
      connection={globalConnection}
      signal_update={signal_update}
      setSelectedChain={setSelectedChain}
      setSelectedToken={setSelectedToken}
      selectedToken={selectedToken}
      selectedChain={selectedChain}
      setNetwork={setNetwork}
      setCrypto={setCrypto}
      countries={countries}
    />
  }

  const setNetwork = async (chain) => {
    if (!chain) { return false; }
    setSelectedToken(null);
    ApiService.setNetwork({ networkId: chain?.chainId }).then(() => {
      setSelectedChain(chain);
      // setSelectedToken(chain.tokens[0] || null);
      setCrypto(chain.tokens[0] || null)
    }).catch(error => AlertService.alert("error", error))
    // setSelectedChain(await switchChain(config, { chainId: chain.id }))
  }

  const setCrypto = async (_token) => {
    if (!_token) { return false; }
    ApiService.setCrypto({ cryptoCurrencyId: _token?.id }).then(() => {
      // setToken(token)
      setSelectedToken(_token)
    }).catch(error => AlertService.alert("error", error))
  }

  return (
    <div>
      <AuthModal />
      {/* <VeriffModal /> */}
      {
        cryllexChains && cryllexChains.length && walletConnectChains && walletConnectChains.length ?
          <div>
            {(() => {
              switch (selectedChain?.platformType) {
                case AVALANCHE_PLATFORM_TYPE:
                  return (
                    <WalletConnectComponent chains={walletConnectChains}>
                      {setMainContent(cryllexChains)}
                    </WalletConnectComponent>
                  );
                case ETHEREUM_PLATFORM_TYPE:
                  return (
                    <WalletConnectComponent chains={walletConnectChains}>
                      {setMainContent(cryllexChains)}
                    </WalletConnectComponent>
                  );
                case SOLANA_PLATFORM_TYPE:
                  return (
                    <SolanaComponent chains={walletConnectChains}>
                      {setMainContent(cryllexChains)}
                    </SolanaComponent>
                  );
                case TRON_PLATFORM_TYPE:
                  return (
                    <TronComponent chains={walletConnectChains}>
                      {setMainContent(cryllexChains)}
                    </TronComponent>
                  );
                default:
                  return (
                    <WalletConnectComponent chains={walletConnectChains}>
                      {setMainContent(cryllexChains)}
                    </WalletConnectComponent>
                  );
              }
            })()}
          </div>
          : null
      }
    </div>
  )
}
