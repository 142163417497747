import { useWallet } from '@solana/wallet-adapter-react';
import React from 'react';

const SolanaWalletInfo = ({ amount = 0, selectedChain, selectedToken, allBalance }) => {

  const { wallet, connected, connect, disconnect, publicKey } = useWallet();

  return (
    <>
      {connected && <p className='mb-1 capitalize'><b>Status: </b><span className='text-success' style={{ fontWeight: "500" }}>Connected</span></p>}
      {publicKey && <p className='word-break-break-word mb-1'><b>Address: </b><span style={{ fontWeight: "500" }}>{publicKey.toBase58()}</span></p>}
      {selectedChain && <p className='word-break-break-word mb-1 d-flex gap-1 align-items-center'><b>Network: </b> <span style={{ fontWeight: "500" }}>{selectedChain.chainName} </span> <img className='rounded' width={20} src={selectedChain.chainLogo} alt="/" /></p>}
      {selectedChain && <p className='mb-1 capitalize d-flex gap-1 align-items-center'><b>Address Balance: </b><span style={{ fontWeight: "500" }}>0</span><img className='rounded' width={20} src={selectedChain.currencyLogo} alt="/" /></p>} 
      {selectedToken && <p className='mb-1 capitalize d-flex gap-1 align-items-center'><b>Token Balance: </b><span style={{ fontWeight: "500" }}>0</span><img className='rounded' width={20} src={selectedToken.logo} alt="/" /></p>}
      {selectedToken && <p className='word-break-break-word mb-1 d-flex gap-1 align-items-center'><b>Amount: </b><span style={{ fontWeight: "500" }}>{amount}</span> <img className='rounded' width={20} src={selectedToken.logo} alt="/" /></p>}
    </>

  );
}

export default SolanaWalletInfo
