import axios from 'axios';
import store from "./../Store/index.js"
import AlertService from './AlertService.js';
import { setUserData, setUserToken } from '../Store/Reducers/userReducer.js';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL_KEY || "",
  withCredentials: true,
});

const chainApi = axios.create({
  baseURL: process.env.REACT_APP_CHAIN_URL_KEY || "",
  withCredentials: true,
});

api.interceptors.request.use(async config => {
  config.headers = {
    'Authorization': localStorage.getItem("userToken") ? localStorage.getItem("userToken") : "",
    'token': localStorage.getItem("token") ? localStorage.getItem("token") : "",
    // 'Content-Type': 'application/json; charset=utf-8;',
    language: "en",
  };

  return config;
});

api.interceptors.response.use(response => {
  if (response.data) return response.data;
}, error => {
  if (error.response) {
    if (error.response.status === 404) { // Status Code: 404
      return Promise.reject("404 Not Found");
    } else if (error.response.status === 401) {
      const refreshToken = localStorage.getItem("refreshToken");
      const user = localStorage.getItem("user");
      const token = localStorage.getItem("userToken");
      if (!refreshToken || !user || !token) {
        logout();
      } else {
        getUserDataByRefreshToken(refreshToken);
      }
    } else if (error.response.status === 500) { // Status Code: 500
      return Promise.reject("500 Internal Server Error");
    }
    if (typeof error.response.data === "object") {
      const currentError = { ...error.response.data };
      const errors = currentError.errors?.Error;
      let errorMessages = [];
      if (errors && errors.length > 0) {
        errors.forEach((error) => {
          const errorParams = error.params;
          if (errorParams) {
            // let errorMsg = `${errorParams?.Description || errorParams?.Message} ${errorParams?.ErrorNumber ? `(${errorParams?.ErrorNumber})` : ""}`
            let errorMsg = `${errorParams?.Description || errorParams?.Message}`
            errorMessages.push(errorMsg);
          }
        });
      } else {
        return Promise.reject("Unrecognized error");
      }
      if (errorMessages.length) {
        showError(errorMessages)
        return Promise.reject();
      } else {
        return Promise.reject("Unrecognized error");
      }
    } else {
      return Promise.reject("Unrecognized error");
    }

  } else {
    return Promise.reject("Unrecognized error");
  }
});

const logout = () => {
  store.dispatch(setUserData(null));
  store.dispatch(setUserToken(null));
}

const getUserDataByRefreshToken = (refreshToken) => {
  const formData = new FormData();
  formData.append(`refreshToken`, refreshToken);
  ApiService.getUserDataByRefreshToken(formData).then(response => {
    if (response.data && response.data.accessToken) {
      const data = { ...response.data };
      store.dispatch(setUserToken(data));
    } else {
      logout();
    }
  }).catch(error => AlertService.alert("error", error));
}

const showError = (errorMessages) => {
  // errorMessages.forEach(error => {
  //   AlertService.alert("error", error)
  // });

  const filteredNumbers = errorMessages.filter(item => !isNaN(item)); //error numbers

  const filteredStrings = errorMessages.filter(item => isNaN(item));
  filteredStrings.forEach(error => {
    AlertService.alert("error", error)
  });
}

class ApiService {

  static validateKyc(data) {
    return api.post(`/kyc/check`, data)
  };
  static verifyIsKycAlreadyDone() {
    return api.get(`/kyc-info`)
  };
  static getCryptos() {
    return api.get(`/api/crypto/list`)
  };
  static getWallets() {
    return api.get(`/api/wallet/list`)
  };
  static sendKycData(data) {
    return api.post(`/kyc`, data)
  };
  static setCrypto(data) {
    return api.post(`/set-crypto`, data)
  };
  static setNetwork(data) {
    return api.post(`/set-network`, data)
  };
  static selectWallet(data) {
    return api.post(`/set-wallet`, data)
  };
  static analyzePaymentData(token) {
    return api.get(`/get/${token}`)
  };
  static loadOrgData(organizationId) {
    return api.get(`/api/organization/paymentOrg/?orgId=${organizationId}`)
  };
  static pay() {
    return api.post(`/pay`)
  };
  static cancelPayment(token) {
    return api.post(`/cancel/${token}`)
  };
  static qrpay() {
    return api.get(`/qrpay`)
  };
  static paySuccess(data) {
    return api.post(`/hash`, data)
  };
  static payError(data) {
    return api.post(`/pay-error`, data)
  };
  static getAccountBalance(address, chinId) {
    return api.post(`/Sqaner/GetAddressBalance?address=${address}&chinId=${chinId}`)
  };
  static getCountries() {
    return api.get(`/api/Country/GetAll`)
  };
  static getChains() {
    return chainApi.get(`/ChainConfig/GetAll`)
  };
  static googleLogin(data) {
    return api.post(`/api/user/googleAuth`, data);
  }
  static getCurrentUser() {
    return api.get(`/api/user/current`);
  }
  static userRegistration(data) {
    return api.post(`/api/User/CreateCustomer`, data);
  }
  static userForgotPassword(email) {
    return api.post(`/api/User/GetRestorePasswordUrl?email=${email}`);
  }
  static userRestorePassword(data) {
    return api.post(`/api/User/RestorePassword`, data);
  }
}

export default ApiService;
