// WalletConnectContext.js
import React, { createContext, useContext } from 'react';

// Создайте контекст
const WalletConnectContext = createContext(null);

// Хук для использования контекста
export const useWagmiConfig = () => {
  return useContext(WalletConnectContext);
};

// Экспортируйте сам контекст
export default WalletConnectContext;
