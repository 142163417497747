// import React, { memo } from 'react'
// import { ClipLoader } from "react-spinners"

// const OTP = memo((props) => {

//   const {
//     isTransactionValidated,
//     kyc_numbers,
//     showOTPloader,
//     onOTPChange,
//     onOTPKeyDown
//   } = props;

//   return (
//     <div id="collapseOtpPassword" className="accordion-collapse collapse show"
//       aria-labelledby="otpPassword">
//       <div className="" id="kyc_numbers">
//         <div className="cx-accordion-body-list d-flex justify-content-center cx-overflow-inherit position-relative">
//           {
//             !isTransactionValidated && kyc_numbers.map((kyc_number, index) => {
//               return <div
//                 key={index}
//                 className={`cx-accordion-body-number-item  ${showOTPloader ? "disabled" : ""}`}
//               >
//                 <input
//                   type="number"
//                   value={kyc_number}
//                   className={`kyc-number`}
//                   id={`kyc_${index}`}
//                   rel={index}
//                   onChange={(event) => {
//                     if (!showOTPloader) onOTPChange(event, index)
//                   }}
//                   onKeyDown={(event) => {
//                     if (!showOTPloader) onOTPKeyDown(event, index)
//                   }}
//                 />
//               </div>
//             })
//           }
//           {
//             showOTPloader ?
//               <div className="position-absolute d-flex">
//                 <ClipLoader size={30} color={"#ffffff"} />
//               </div>
//               : null
//           }
//           {
//             isTransactionValidated && !showOTPloader ?
//               <p className="mt-2 d-block bold mb-0">SMS verified. You may proceed with payment.</p>
//               : null
//           }
//         </div>
//       </div>
//     </div>
//   )
// })
// export default OTP













import React, { useState } from 'react'
import { Accordion } from 'react-bootstrap';
import OtpInput from 'react-otp-input'
import { ClipLoader } from "react-spinners"
// import { useAccount } from 'wagmi';
import cryllexSvg from "./../../../../assets/images/crillex.svg"

export default function OTP(props) {

  // const { isConnected } = useAccount();
  const [isConnected, setIsConnected] = useState(false)

  const {
    isTransactionValidated,
    kyc_numbers,
    set_kyc_numbers,
    showOTPloader,
    isLoading,
    setSelectedAccordionKeys,
    selectedAccordionKeys
  } = props;

  return (
    <Accordion.Item
      eventKey={4}
      // className={!isConnected ? "pointer-events-none" : ""}
    >
      <Accordion.Header
        className={isLoading ? "disabled-accordion" : ""}
        onClick={() => {
          if (document.getElementById("kyc_0")) {
            setTimeout(() => {
              document.getElementById("kyc_0").focus();
            }, 500);
          };
          if (selectedAccordionKeys.find(key => +key === 4)) {
            setSelectedAccordionKeys(selectedAccordionKeys.filter(key => +key !== 4))
          } else {
            setSelectedAccordionKeys([...selectedAccordionKeys, 4])
          }

        }}
      >
        <img src={cryllexSvg} alt="/" />
        {
          isTransactionValidated ?
            "SMS verified. You may proceed with payment." :
            " Please, type OTP Code (One time password)"
        }
      </Accordion.Header>
      <Accordion.Body>
        <div className="bg-white shadow p-3 text-dark" style={{ borderRadius: "10px" }}>
          <div id="collapseOtpPassword" className="accordion-collapse collapse show" aria-labelledby="otpPassword">
            <div className="" id="kyc_numbers">
              {
                !isTransactionValidated ?

                  <div className="cx-accordion-body-list d-flex justify-content-center cx-overflow-inherit position-relative bg-white">
                    <div
                      className={`cx-accordion-body-number-item  ${showOTPloader || isTransactionValidated ? "disabled" : ""}`}
                    >
                      <OtpInput
                        value={kyc_numbers}
                        onChange={set_kyc_numbers}
                        inputType="number"
                        numInputs={8}
                        shouldAutoFocus={true}
                        renderSeparator={<span>&nbsp;</span>}
                        renderInput={(props, index) => <input {...props} id={`kyc_${index}`} className="kyc-number" disabled={showOTPloader} />}
                      />
                    </div>
                    {
                      showOTPloader ?
                        <div className="position-absolute d-flex">
                          <ClipLoader size={30} color={"#ffffff"} />
                        </div>
                        : null
                    }
                  </div>
                  : null
              }
              {
                isTransactionValidated && !showOTPloader ?
                  <p className="mt-2 d-block bold mb-0 text-center">SMS verified. You may proceed with payment.</p>
                  : null
              }
            </div>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>

  )
}
