import { createSlice } from "@reduxjs/toolkit";

const blackListSlice = createSlice({
  name: "balckList",
  initialState: {
    isShowBlackListModal: false,
  },
  reducers: {
    setIsShowBlackListModal: (state, action) => {
      state.isShowBlackListModal = action.payload
    },
  },
});

export const {
  setIsShowBlackListModal,
} = blackListSlice.actions;

export default blackListSlice.reducer;
