import { createSlice } from "@reduxjs/toolkit";

const mianSlice = createSlice({
  name: "main",
  initialState: {
    token: localStorage.getItem("token"),
  },
  reducers: {
    setToken: (state, { payload }) => {
      if (payload) {
        localStorage.setItem("token", payload);
      } else {
        localStorage.removeItem("token")
      }
      state.token = payload
    },
  },
});

export const {
  setToken,
} = mianSlice.actions;

export default mianSlice.reducer;
