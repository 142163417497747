import React, { useEffect } from 'react'
import { Accordion, Button } from 'react-bootstrap'
import cryllexSvg from "./../../../../assets/images/crillex.svg"
import { extractAddress } from '../helper'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { useWallet } from '@solana/wallet-adapter-react';
import SolanaWalletInfo from '../WalletInfo/SolanaWalletInfo'

export default function SolanaWalletConfig({
  selectedAccordionKeys,
  setSelectedAccordionKeys,
  isLoading,
  amount,
  allBalance,
  selectedChain,
  selectedToken,
  setAddress
}) {

  const { wallet, connected, connect, disconnect, publicKey } = useWallet();

  useEffect(() => {
    if (publicKey && publicKey.toBase58()) {
      setAddress(publicKey.toBase58());
    }
  }, [publicKey])

  return (
    <Accordion.Item
      eventKey={2}
    // className={!selectedChain ? "pointer-events-none" : ""}
    >
      <Accordion.Header
        className={isLoading ? "disabled-accordion" : ""}
        onClick={() => {
          if (selectedAccordionKeys.find(key => +key === 1)) {
            setSelectedAccordionKeys(selectedAccordionKeys.filter(key => +key !== 1))
          } else {
            setSelectedAccordionKeys([...selectedAccordionKeys, 1])
          }
        }}
      >
        <img src={cryllexSvg} alt="/" />
        {
          publicKey ? `Wallet address ${extractAddress(publicKey.toBase58())}` : " Connect your wallet"
        }

      </Accordion.Header>
      <Accordion.Body>
        <div className="bg-white shadow p-3 text-dark" style={{ borderRadius: "10px" }}>
          {
            !connected ?
              <div className='solana-connect-button shadow'>
                {/* <Button variant="primary w-100 shadow mb-2" onClick={connect}>Connect Your Solana Wallet</Button> */}
                <WalletMultiButton>Connect Your Solana Wallet</WalletMultiButton>
              </div> :
              <div>
                <SolanaWalletInfo
                  amount={amount}
                  allBalance={allBalance}
                  selectedChain={selectedChain}
                  selectedToken={selectedToken}
                />
                <hr />
                <div className="d-flex justify-content-end">
                  {/* <WalletDisconnectButton>Disconnect Wallet</WalletDisconnectButton> */}
                  <Button variant="danger" onClick={disconnect}>Disconnect Wallet</Button>
                </div>
              </div>
          }
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}
