import React, { memo, useContext, useState } from 'react';
import cryllexSvg from "./../../../../assets/images/crillex.svg"

const StellarPayBtn = memo(({
    price,
    paymentData,
    isTransactionValidated,
    pay,
    selectedToken,
    amount,
    paySuccess,
    payError,
    selectedChain,
    to,
}) => {

    const [account, setAccount] = useState("");


    const _sendTransaction = () => {
        console.log("_sendTransaction");
    }

    return paymentData ? <div className="m-5">
        <div
            id="pay"
            className={`cx-pay-container ${isTransactionValidated && account ? "cx-pay-container-active" : ""}`}
            onClick={() => {
                if (isTransactionValidated && account) {
                    pay(_sendTransaction);
                }
            }}>
            <div className="cx-pay-wrapper">
                <img src={cryllexSvg} alt="/" />
                <strong>Pay</strong>
            </div>
            <div className="cx-total-wrapper">
                <p><span className="inf_fiatPrice"><b>{paymentData.fiatAmount ? `$ ${paymentData.fiatAmount}` : ""}</b></span></p>
                <span className="cx-order-list-item-value">
                    {
                        price ?
                            <span className="totalcost ms-2"><b>{price} {selectedToken?.slug}</b></span>
                            : null
                    }
                </span>
            </div>
        </div>
    </div> : null
})

export default StellarPayBtn;

