// import React, { useState } from 'react';
// import TronContext from './TronContext';

// const TronComponent = ({ children }) => {

//   const [tronWeb, setTronWeb] = useState(null);
//   const [account, setAccount] = useState(null);

//   const connectWallet = async () => {
//     if (window.tronLink) {
//       try {
//         await window.tronLink.request({ method: 'tron_requestAccounts' });
//         const tronWebInstance = window.tronWeb;
//         if (tronWebInstance) {
//           setTronWeb(tronWebInstance);
//           const defaultAddress = tronWebInstance.defaultAddress.base58;
//           if (defaultAddress) {
//             setAccount(defaultAddress);
//           } else {
//             console.error('Не удалось получить адрес по умолчанию');
//           }
//         } else {
//           console.error('TronWeb не инициализирован');
//         }
//       } catch (error) {
//         console.error('Ошибка при подключении к TronLink:', error);
//       }
//     } else {
//       alert('Пожалуйста, установите TronLink!');
//     }
//   };

//   const sendTransaction = async (to, amount, token) => {
//     if (!tronWeb || !to || !amount || !token) {
//       throw new Error("Missing required parameters");
//     }
//     try {
//       const contract = await tronWeb.contract().at(token.contractAddress);
//       const txHash = await contract.transfer(to, tronWeb.toSun(amount)).send();
//       return txHash;
//     } catch (error) {
//       console.error('Error sending transaction:', error);
//       throw error;
//     }
//   };

//   const disconnectWallet = () => {
//     setTronWeb(null);
//     setAccount(null);
//   };

//   // Передаем значения через контекст
//   const contextValue = {
//     tronWeb,
//     account,
//     connectWallet,
//     disconnectWallet,
//     sendTransaction,
//   };

//   return (
//     <TronContext.Provider value={contextValue}>
//       {children}
//     </TronContext.Provider>
//   );
// };

// export default TronComponent;








import React, { useState } from 'react';
import TronContext from './TronContext';

const TronComponent = ({ children }) => {
  const [tronWeb, setTronWeb] = useState(null);
  const [account, setAccount] = useState(null);

  const connectWallet = async () => {
    if (window.tronLink) {
      try {
        await window.tronLink.request({ method: 'tron_requestAccounts' });
        const tronWebInstance = window.tronWeb;
        if (tronWebInstance) {
          setTronWeb(tronWebInstance);
          const defaultAddress = tronWebInstance.defaultAddress.base58;
          if (defaultAddress) {
            setAccount(defaultAddress);
          } else {
            alert(`Failed to get default address. Please log in to your TronLink wallet.`);
          }
        } else {
          alert(`TronWeb is not initialized`);
        }
      } catch (error) {
        alert(`Error connecting to TronLink: ${error?.message}`);
      }
    } else {
      alert('Please install TronLink!');
    }
  };

  const sendTransaction = async (to, amount, token) => {
    if (!tronWeb || !to || !amount || !token) {
      throw new Error("Missing required parameters");
    }
    try {
      const contract = await tronWeb.contract().at(token.contractAddress);
      const txHash = await contract.transfer(to, tronWeb.toSun(amount)).send();
      return txHash;
    } catch (error) {
      console.error('Error sending transaction:', error);
      throw error;
    }
  };

  const disconnectWallet = () => {
    setTronWeb(null);
    setAccount(null);
  };

  // Providing values through context
  const contextValue = {
    tronWeb,
    account,
    connectWallet,
    disconnectWallet,
    sendTransaction,
  };

  return (
    <TronContext.Provider value={contextValue}>
      {children}
    </TronContext.Provider>
  );
};

export default TronComponent;
