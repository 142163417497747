import React, { memo, useCallback } from 'react';
import { parseUnits } from 'viem';
import cryllexSvg from "./../../../../assets/images/crillex.svg"
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { PublicKey, Transaction, Connection } from '@solana/web3.js';
import { createTransferInstruction, getOrCreateAssociatedTokenAccount, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { useSolana } from '../../../../SolanaContext';

const SolanaPayBtn = memo(({
    price,
    paymentData,
    isTransactionValidated,
    pay,
    selectedToken,
    amount,
    paySuccess,
    payError,
    selectedChain,
    to,
}) => {

    const endpoint = useSolana();
    const { publicKey, sendTransaction, disconnect, connected } = useWallet();
    const connection = new Connection(endpoint, 'confirmed');


    const _sendTransaction = useCallback(async () => {
        if (!publicKey) throw new WalletNotConnectedError();
        const toPublicKey = new PublicKey(to);
        const mintPublicKey = new PublicKey(selectedToken.contrcatAddress);
        const parsedAmount = parseUnits(amount, selectedToken.decimal);
        try {
            const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
                connection,
                publicKey,
                mintPublicKey,
                publicKey
            );

            const toTokenAccount = await getOrCreateAssociatedTokenAccount(
                connection,
                toPublicKey,
                mintPublicKey,
                toPublicKey
            );

            const transaction = new Transaction().add(
                createTransferInstruction(
                    fromTokenAccount.address,  // адрес аккаунта отправителя
                    toTokenAccount.address,    // адрес аккаунта получателя
                    publicKey,                 // публичный ключ отправителя
                    // +amount * 1000000,                 // количество токенов для перевода (1 USDT, 6 знаков после запятой)
                    parsedAmount,
                    [],
                    TOKEN_PROGRAM_ID,
                )
            );
            const txHash = await sendTransaction(transaction, connection);
            console.log('Transaction hash:', txHash);
            paySuccess(txHash, selectedChain.id);
        } catch (error) {
            payError(error.name, error.message)
        }

    }, [publicKey, sendTransaction, endpoint, to, amount]);


    return paymentData ? <div className="m-5">
        <div
            id="pay"
            className={`cx-pay-container ${isTransactionValidated && connected ? "cx-pay-container-active" : ""}`}
            onClick={() => {
                if (isTransactionValidated && connected) {
                    pay(_sendTransaction);
                }
            }}>
            <div className="cx-pay-wrapper">
                <img src={cryllexSvg} alt="/" />
                <strong>Pay</strong>
            </div>
            <div className="cx-total-wrapper">
                <p><span className="inf_fiatPrice"><b>{paymentData.fiatAmount ? `$ ${paymentData.fiatAmount}` : ""}</b></span></p>
                <span className="cx-order-list-item-value">
                    {
                        price ?
                            <span className="totalcost ms-2"><b>{price} {selectedToken?.slug}</b></span>
                            : null
                    }
                </span>
            </div>
        </div>
    </div> : null
})

export default SolanaPayBtn;

