import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import MainService from "../../Services/MainService";
import { FaRegEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { ImSpinner3 } from "react-icons/im";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GoogleLoginButton from "./GoogleAuth";
import { IdentityService } from "../../Services/IdentityService";
import AlertService from "../../Services/AlertService";
import { setUserData, setUserToken } from "../../Store/Reducers/userReducer";
import ApiService from "../../Services/ApiService";

const LoginModal = () => {

  const { token } = useSelector(state => state.main);
  const { userToken } = useSelector(state => state.user);
  const dispatch = useDispatch()
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const [values, setValues] = useState({
    email: "",
    password: "",
  })

  // useEffect(() => {
  //   if (!userToken) { return false; }
  //   getCurrentUser();

  //   return () => { }
  // }, [userToken])

  const getCurrentUser = () => {
    setIsLoading(true);
    ApiService.getCurrentUser().then(response => {
      if (response && response.data) {
        dispatch(setUserData(response.data));
      }
    }).catch(error => AlertService.alert("error", error)).finally(() => {
      setIsLoading(false);
    })
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (!values.email.trim().length || !values.password.trim().length) {
      return false;
    }
    setIsLoading(true);
    let data = {
      username: values.email,
      password: values.password,
    }
    IdentityService.login(data).then(response => {
      if (response && response.data) {
        dispatch(setUserToken(response.data));

        setTimeout(() => {
          getCurrentUser();
        }, 100);
      }
    }).catch(error => AlertService.alert("error", error)).finally(() => {
      setIsLoading(false);
    })
  }

  return (
    <React.Fragment>
      <Modal.Header>
        <Modal.Title>
          Sign In
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>

          <div>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email Address*</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                required
                value={values.email}
                onChange={(event) => MainService.onChange({ event, fieldName: "email", cb: setValues, maxLength: 120 })}
              />
            </Form.Group>
            <Form.Group className="position-relative mb-3" controlId="formBasicPassword">
              <Form.Label>Password*</Form.Label>
              <Form.Control
                type={`${isShowPassword ? "text" : "password"}`}
                placeholder="Enter password"
                value={values.password}
                onChange={(event) => MainService.onChange({ event, fieldName: "password", cb: setValues, maxLength: 120 })}
              />
              <div className='eye-block' onClick={() => setIsShowPassword(!isShowPassword)}>
                {
                  isShowPassword ?
                    <FaRegEye size={18} color='grey' />
                    :
                    <FaEyeSlash size={18} color='grey' />
                }
              </div>
            </Form.Group>
            <div className="d-flex justify-content-between">
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Remember me"
                  value={rememberMe}
                  onChange={(event => setRememberMe(event.target.checked))}
                />
              </Form.Group>
              <div>
                <Link to={`/${token}?page=${3}`}
                  className={`${isLoading ? "disabled" : ""}`}
                >
                  Forgot password ?
                </Link>
              </div>
            </div>
          </div>
          <div className='mt-4 mb-3'>
            <div className='d-flex justify-content-center my-3'>
              {
                isLoading ?
                  <Button type='submit' variant="primary" className='px-4 w-100' disabled>
                    <span className='me-2'>Loading</span>
                    <ImSpinner3 className='rotating' />
                  </Button>
                  :
                  <Button type='submit' variant="primary" className='px-4 w-100'>
                    Sign In
                  </Button>
              }
            </div>
            {/* <div className="google-auth-block">
              <div className="alternative">
                <div className="line w-100"></div>
                <span>or</span>
                <div className="line w-100"></div>
              </div>
              <div className="d-flex justify-content-center ">
                <GoogleLoginButton />
              </div>
            </div> */}
            <div className='d-flex gap-2 justify-content-center'>
              <span> Not registred yet ?</span>
              <Link to={`/${token}?page=${2}`}
                className={`${isLoading ? "disabled" : ""}`}
              >
                Create an account
              </Link>
            </div>
          </div>
        </Form>
      </Modal.Body>

    </React.Fragment>
  )
}

export default LoginModal