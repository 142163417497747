import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import MainService from "../../Services/MainService";
import { FaRegEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { ImSpinner3 } from "react-icons/im";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ApiService from "../../Services/ApiService";
import AlertService from "../../Services/AlertService";

const ForgotPassword = () => {

  const { token } = useSelector(state => state.main);

  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const [values, setValues] = useState({
    email: "",
  })

  const onSubmit = (event) => {
    event.preventDefault();
    if (!values.email.trim().length) {
      return false;
    }
    setIsLoading(true);
    ApiService.userForgotPassword(values.email).then(() => {
      setIsSent(true);
    }).catch(error => AlertService.alert("error", error)).finally(() => {
      setIsLoading(false);
    })
  }

  return (
    <React.Fragment>
      <Modal.Header>
        <Modal.Title>
          {
            isSent ?
              "Password Reset Request Sent!"
              : "Forgot Password ?"
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          isSent ?
            <p className='mb-4'>We have sent a password reset link to your email. Please check your inbox and follow the link to reset your password.</p>
            :
            <Form onSubmit={onSubmit}>
              <div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email Address*</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={values.email}
                    onChange={(event) => MainService.onChange({ event, fieldName: "email", cb: setValues, maxLength: 120 })}
                  />
                  <Form.Text className="text-muted">
                    After entering your email, we will send you a verification link. Please check your inbox and follow the instructions to reset your password.
                  </Form.Text>
                </Form.Group>
              </div>
              <div className='mt-4 mb-3'>
                <div className='d-flex justify-content-center my-3'>
                  {
                    isLoading ?
                      <Button type='submit' variant="primary" className='px-4 w-100' disabled>
                        <span className='me-2'>Loading</span>
                        <ImSpinner3 className='rotating' />
                      </Button>
                      :
                      <Button type='submit' variant="primary" className='px-4 w-100'>
                        Send
                      </Button>
                  }
                </div>
                <div className='d-flex gap-2 justify-content-center'>
                  <span> You already have an account ?</span>
                  <Link to={`/${token}?page=${1}`}
                    className={`${isLoading ? "disabled" : ""}`}
                  >
                    Sign In
                  </Link>
                </div>
              </div>
            </Form>
        }

      </Modal.Body>

    </React.Fragment>
  )
}

export default ForgotPassword