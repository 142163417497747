import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import MainService from "../../Services/MainService";
import { FaRegEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { ImSpinner3 } from "react-icons/im";
import ApiService from "../../Services/ApiService";
import AlertService from "../../Services/AlertService";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { IdentityService } from "../../Services/IdentityService";
import { setUserData, setUserToken } from "../../Store/Reducers/userReducer";

const RestorePassword = (props) => {

  const dispatch = useDispatch()
  const [searchParams] = useSearchParams();
  const guid = searchParams.get('guid');
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [values, setValues] = useState({
    password: "",
  })

  const onSubmit = (event) => {
    event.preventDefault();
    if (!values.password.trim().length) {
      return false;
    }
    setIsLoading(true);
    let data = {
      password: window.btoa(values.password),
      confirmPassword: window.btoa(values.password),
      key: guid
    }
    ApiService.userRestorePassword(data).then((response) => {
      if (response && response.data) {
        login(response.data)
      }
    }).catch(error => AlertService.alert("error", error)).finally(() => {
      setIsLoading(false);
    })
  }

  const getCurrentUser = () => {
    ApiService.getCurrentUser().then(response => {
      if (response && response.data) {
        dispatch(setUserData(response.data));
      }
    }).catch(error => AlertService.alert("error", error)).finally(() => {
      setIsLoading(false);
    })
  }

  const login = (email) => {
    let data = {
      username: email,
      password: values.password,
    }
    IdentityService.login(data).then(response => {
      if (response && response.data) {
        dispatch(setUserToken(response.data));
        setTimeout(() => {
          getCurrentUser();
        }, 100);
      }
    }).catch(error => AlertService.alert("error", error))
  }


  return (
    <React.Fragment>
      <Modal.Header>
        <Modal.Title>
          Restore Password
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>

          <div>
            <Form.Group className="position-relative mb-3" controlId="formBasicPassword">
              <Form.Label>Password*</Form.Label>
              <Form.Control
                type={`${isShowPassword ? "text" : "password"}`}
                placeholder="Enter new password"
                value={values.password}
                onChange={(event) => MainService.onChange({ event, fieldName: "password", cb: setValues, maxLength: 120 })}
              />
              <div className='eye-block' onClick={() => setIsShowPassword(!isShowPassword)}>
                {
                  isShowPassword ?
                    <FaRegEye size={18} color='grey' />
                    :
                    <FaEyeSlash size={18} color='grey' />
                }
              </div>
            </Form.Group>
          </div>
          <div className='mt-4 mb-3'>
            <div className='d-flex justify-content-center my-3'>
              {
                isLoading ?
                  <Button type='submit' variant="primary" className='px-4 w-100' disabled>
                    <span className='me-2'>Loading</span>
                    <ImSpinner3 className='rotating' />
                  </Button>
                  :
                  <Button type='submit' variant="primary" className='px-4 w-100'>
                    Send
                  </Button>
              }
            </div>
          </div>
        </Form>
      </Modal.Body>

    </React.Fragment>
  )
}

export default RestorePassword