// import React, { memo, useEffect } from "react";
// import $ from "jquery"
// import AlertService from "../Services/AlertService";
// import * as signalR from '@microsoft/signalr';
// import { Link } from "react-router-dom";
// import checkSvg from "./../assets/icons/check.svg"

// var oldStat = null;
// const TransactionWaiting = (props) => {

//     const { connection, paymentData, cancelPayment } = props;

//     console.log(paymentData, "paymentData");

//     useEffect(() => {
//         if (paymentData) {
//             setPaymentStatus(paymentData.status);
//         }
//     }, [paymentData, paymentData?.status]);

//     // useEffect(() => {
//     //     if (connection && connection.state === signalR.HubConnectionState.Connected) {
//     //         connection.on("paymentstatus", (stat) => {
//     //             try {
//     //                 debugger
//     //                 setPaymentStatus(stat);
//     //             } catch (error) {
//     //                 AlertService.alert("error", error)
//     //             }
//     //         });
//     //     }
//     // }, [connection])

//     const setPaymentStatus = (stat) => {
//         if (oldStat != stat) {
//             $('.txPart').hide();
//             $('#trxwaiting #txPart_' + stat).fadeIn();
//             oldStat = stat;
//             // switch (stat) {
//             //     case 5:
//             //         break;
//             // }
//         }
//         // $('#vwOnEthScan').attr('href', `https://etherscan.io/tx/${paymentData.cryptoTransactionHash}`)
//         // var query = `rtx=${paymentData.localTransactionId}&ltx=${paymentData.remoteTransactionId}`;
//         // var url;
//         // if (paymentData.successUrl.indexOf("?") == -1) {
//         //     url = `${paymentData.successUrl}?${query}`
//         // } else {
//         //     url = `${paymentData.successUrl}&${query}`
//         // }
//         // $('#url_success').attr('href', `${paymentData.successUrl}`);
//     }

//     if (!paymentData) { return false; }

//     return <div className="cx-modal-container modal-show" id="trxwaiting">
//         <div className="cx-modal-wrapper cx-small-modal cx--gray-background">
//             <div className="cx-modal-body-container">

//                 <div className="cx-modal-body-wrapper txPart display_none" id="txPart_0">
//                     <p className="cx-d-block">A payment request has been sent to your wallet</p>
//                     {/* <p className="img_qr_code">
//                         <img src="" id="qrcode" />
//                         <br />Also you can use mobile application, scan QR code and make payment
//                     </p> */}
//                     <p className="cx-d-block">Please accept within</p>
//                     <strong className="cx-d-block cx-blue-color" id="payReqTimer"></strong>
//                 </div>

//                 <div className="cx-modal-body-wrapper txPart display_none" id="txPart_1">
//                     <div className="cx-spiner-wrapper">
//                         <div className="cx-loader-wrapper">
//                             <div className="loader spiner-20"></div>
//                         </div>
//                         <strong className="cx-d-block cx-spiner-text">Waiting for TXN...</strong>
//                     </div>
//                     <div className="cx-cancel-btn mt-3">
//                         <button
//                             id="cancelbtn"
//                             data-href="/delete.php?id=23"
//                             data-toggle="modal"
//                             data-target="#confirm-delete"
//                             onClick={cancelPayment}
//                             className="text-secondary mt-0"
//                         >
//                             Cancel payment
//                         </button>
//                     </div>
//                 </div>

//                 {/* txPart_2 todo */}

//                 <div className="cx-modal-body-wrapper txPart display_none" id="txPart_3">
//                     <p><strong className="cx-d-block">You are cancelled or rejected current transaction!</strong></p>
//                     <Link
//                         to="#"
//                         onClick={() => {
//                             if (paymentData.cancelUrl) {
//                                 window.location.href = paymentData.cancelUrl;
//                             }
//                         }}
//                         className="my-2 d-block" >
//                         Back to merchant
//                     </Link>
//                 </div>

//                 <div className="cx-modal-body-wrapper txPart display_none" id="txPart_4">
//                     <h4>Sorry,</h4>
//                     <strong className="cx-d-block cx-spiner-text">Your transaction rejected!</strong>
//                     <p>Please try again</p>
//                 </div>

//                 <div className="cx-modal-body-wrapper txPart display_none" id="txPart_5">
//                     <div className="cx-merchant-logo">
//                         <div id="orgImage"></div>
//                     </div>
//                     <div className="cx-modal-body-container">
//                         <div className="cx-modal-body-wrapper">
//                             <div className="cx-modal-body">
//                                 <strong className="cx-window-title">Your Order is Complete!</strong>
//                                 <div className="cx-modal-main-title">
//                                     <strong className="orgName">Order: </strong>
//                                     <span className="orderNum">{paymentData.remoteTransactionId ? paymentData.remoteTransactionId : ""}</span>
//                                 </div>
//                                 <div className="cx--modal-icon">
//                                     <img src={checkSvg} />
//                                 </div>
//                                 <a href={`https://etherscan.io/tx/${paymentData.cryptoTransactionHash}`} id="vwOnEthScan" target="_blank">View TXN on Etherscan</a>
//                                 <p className="cx-d-block cx-info-text">
//                                     <br />
//                                     You may now close this window
//                                     <br />
//                                     <a href={`${paymentData.successUrl}`} id="url_success" target="_blank">Return to merchant</a>
//                                 </p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 <div className="cx-modal-body-wrapper txPart display_none" id="txPart_6">
//                     <p><strong className="cx-d-block cx-spiner-text">Transaction has been cancelled successfully</strong></p>
//                     <Link
//                         to="#"
//                         onClick={() => {
//                             if (paymentData.cancelUrl) {
//                                 window.location.href = paymentData.cancelUrl;
//                             }
//                         }}
//                         className="my-2 d-block" >
//                         Back to merchant
//                     </Link>
//                 </div>

//                 <div className="cx-modal-body-wrapper txPart display_none" id="txPart_7">
//                     <h4>Sorry,</h4>
//                     <strong className="cx-d-block cx-spiner-text">Your transaction timed out</strong>
//                     <p>Please try again!</p>
//                 </div>

//             </div>
//         </div>
//     </div>
// }

// export default TransactionWaiting;






import React, { memo } from "react";
import { Link } from "react-router-dom";
import checkSvg from "./../assets/icons/check.svg"
import { Modal, ModalBody } from "react-bootstrap";
import MainService from "../Services/MainService";

const TransactionWaiting = memo(({ paymentData, cancelPayment, isShowTransactionWaitingModal, }) => {

    if (!paymentData) { return false }

    let paymentTime = paymentData?.paymentRequestedDatetime;
    let ptimeNow = MainService.convertUTCDateToLocalDate(new Date(paymentTime));
    let passedSeconds = parseInt(parseInt(Date.now() / 1000) - parseInt(ptimeNow.getTime() / 1000));
    let timeRemaining = 5 * 60 - passedSeconds;

    return <Modal size="sm" show={isShowTransactionWaitingModal && paymentData ? true : false} centered>
        <ModalBody>

            <div className="cx-modal-container">
                <div className="cx-modal-wrapper">
                    <div className="cx-modal-body-container">

                        <div className={`cx-modal-body-wrapper txPart`} style={{ display: paymentData.status === 0 ? "block" : "none" }} id="txPart_0">
                            <p className="cx-d-block">A payment request has been sent to your wallet</p>
                            <p className="cx-d-block">Please accept within</p>
                            <strong className="cx-d-block cx-blue-color" id="payReqTimer">{MainService.secondsToString(timeRemaining)}</strong>
                            {/* <p className="img_qr_code">
                        <img src="" id="qrcode" />
                        <br />Also you can use mobile application, scan QR code and make payment
                    </p> */}
                        </div>
                        <div className={`cx-modal-body-wrapper txPart`} style={{ display: paymentData.status === 1 ? "block" : "none" }} id="txPart_1">
                            <div className="cx-spiner-wrapper">
                                <div className="cx-loader-wrapper">
                                    <div className="loader spiner-20"></div>
                                </div>
                                <strong className="cx-d-block cx-spiner-text">Waiting for TXN...</strong>
                            </div>
                            <div className="cx-cancel-btn mt-3">
                                <button
                                    id="cancelbtn"
                                    data-href="/delete.php?id=23"
                                    data-toggle="modal"
                                    data-target="#confirm-delete"
                                    onClick={cancelPayment}
                                    className="text-secondary mt-0"
                                >
                                    Cancel payment
                                </button>
                            </div>
                        </div>
                        <div className={`cx-modal-body-wrapper txPart`} style={{ display: paymentData.status === 2 ? "block" : "none" }} id="txPart_2">
                            <div className="cx-spiner-wrapper">
                                <p><strong className="cx-d-block">Status code is 2</strong></p>
                                <strong className="cx-d-block cx-spiner-text">ToDo</strong>
                            </div>
                        </div>
                        <div className={`cx-modal-body-wrapper txPart`} style={{ display: paymentData.status === 3 ? "block" : "none" }} id="txPart_3">
                            <p><strong className="cx-d-block">You are cancelled or rejected current transaction!</strong></p>
                            <Link
                                to="#"
                                onClick={() => {
                                    if (paymentData.cancelUrl) {
                                        window.location.href = paymentData.cancelUrl;
                                    }
                                }}
                                className="my-2 d-block" >
                                Back to merchant
                            </Link>
                        </div>
                        <div className={`cx-modal-body-wrapper txPart`} style={{ display: paymentData.status === 4 ? "block" : "none" }} id="txPart_4">
                            <h4>Sorry,</h4>
                            <strong className="cx-d-block cx-spiner-text">Your transaction rejected!</strong>
                            <p>Please try again</p>
                        </div>
                        <div className={`cx-modal-body-wrapper txPart`} style={{ display: paymentData.status === 5 ? "block" : "none" }} id="txPart_5">
                            <div className="cx-merchant-logo">
                                <div id="orgImage"></div>
                            </div>
                            <div className="cx-modal-body-container">
                                <div className="cx-modal-body-wrapper">
                                    <div className="cx-modal-body">
                                        <strong className="cx-window-title">Your Order is Complete!</strong>
                                        <div className="cx-modal-main-title">
                                            <strong className="orgName">Order: </strong>
                                            <span className="orderNum">{paymentData.remoteTransactionId ? paymentData.remoteTransactionId : ""}</span>
                                        </div>
                                        <div className="cx--modal-icon">
                                            <img src={checkSvg} alt="/" />
                                        </div>
                                        <a href={`https://etherscan.io/tx/${paymentData.cryptoTransactionHash}`} id="vwOnEthScan" target="_blank">View TXN on Etherscan</a>
                                        <p className="cx-d-block cx-info-text">
                                            <br />
                                            You may now close this window
                                            <br />
                                            <a href={`${paymentData.successUrl}`} id="url_success" target="_blank">Return to merchant</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`cx-modal-body-wrapper txPart`} style={{ display: paymentData.status === 6 ? "block" : "none" }} id="txPart_6">
                            <p><strong className="cx-d-block cx-spiner-text">Transaction has been cancelled successfully</strong></p>
                            <Link
                                to="#"
                                onClick={() => {
                                    if (paymentData.cancelUrl) {
                                        window.location.href = paymentData.cancelUrl;
                                    }
                                }}
                                className="my-2 d-block" >
                                Back to merchant
                            </Link>
                        </div>
                        <div className={`cx-modal-body-wrapper txPart`} style={{ display: paymentData.status === 7 ? "block" : "none" }} id="txPart_7">
                            <h4>Sorry,</h4>
                            <strong className="cx-d-block cx-spiner-text">Your transaction timed out</strong>
                            <p>Please try again!</p>
                        </div>
                    </div>
                </div>
            </div>


        </ModalBody>
    </Modal>
});

export default TransactionWaiting;