import React, { useContext, useEffect, useState } from 'react'
import { Accordion, Button } from 'react-bootstrap'
import cryllexSvg from "./../../../../assets/images/crillex.svg"
import { extractAddress } from '../helper'
import TronWalletInfo from '../WalletInfo/TronWalletInfo';
import TronContext from '../../../../TronContext';

export default function TronWalletConfig({
  selectedAccordionKeys,
  setSelectedAccordionKeys,
  isLoading,
  amount,
  allBalance,
  selectedChain,
  selectedToken,
  setAddress,
}) {

  const { account, connectWallet, disconnectWallet } = useContext(TronContext);

  useEffect(() => {
    if (account) {
      setAddress(account);
    }
  }, [account])

  return (
    <Accordion.Item
      eventKey={2}
    // className={!selectedChain ? "pointer-events-none" : ""}
    >
      <Accordion.Header
        className={isLoading ? "disabled-accordion" : ""}
        onClick={() => {
          if (selectedAccordionKeys.find(key => +key === 1)) {
            setSelectedAccordionKeys(selectedAccordionKeys.filter(key => +key !== 1))
          } else {
            setSelectedAccordionKeys([...selectedAccordionKeys, 1])
          }
        }}
      >
        <img src={cryllexSvg} alt="/" />
        {
          account ? `Wallet address ${extractAddress(account)}` : " Connect your wallet"
        }

      </Accordion.Header>
      <Accordion.Body>
        <div className="bg-white shadow p-3 text-dark" style={{ borderRadius: "10px" }}>
          {
            !account ?
              <div>
                <Button variant="primary w-100 shadow" onClick={connectWallet}>Connect Your Tron Wallet</Button>
              </div> :
              <div>
                <TronWalletInfo
                  amount={amount}
                  allBalance={allBalance}
                  selectedChain={selectedChain}
                  selectedToken={selectedToken}
                />
                <hr />
                <div className="d-flex justify-content-end">
                  <Button variant="danger" onClick={disconnectWallet}>Disconnect Wallet</Button>
                </div>
              </div>
          }
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}


