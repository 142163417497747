import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import warningSvg from "../assets/icons/warning.svg";
import { toast } from 'react-toastify';

const getAlertType = (type, cb, error) => {
  if (!type) { cb.error(error) };
  switch (type) {
    case 'info':
      return cb.info(error, {position: toast.POSITION.BOTTOM_LEFT});
    case 'success':
      return cb.success(error, { position: toast.POSITION.BOTTOM_LEFT });
    case 'warning':
      return cb.warning(error, { position: toast.POSITION.BOTTOM_LEFT });
    case 'error':
      return cb.error(error, { position: toast.POSITION.BOTTOM_LEFT });
    default:
      break;
  }
}

export default class AlertService {

  static alert = (type, error) => {
    const respMessage = typeof error === "object" ? error.message || error.respmess : error;
    if (!respMessage || (respMessage && respMessage.includes("401"))) { return false; }
    getAlertType(type, toast, respMessage);
  }

  static alertConfirm = (title, message, yes, no) => {
    return new Promise((resolve, reject) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert">
              <div className="react-confirm-alert-body">
                <div className='react-confirm-alert-title-block'>
                  <img className='mr-2' src={warningSvg} alt="" />
                  {
                    title ? <h3>{title}</h3> : null
                  }
                  <hr />

                </div>
                {
                  message ?
                    <>
                      <p>{message}</p>
                      <hr />
                    </>
                    : null
                }
                <div className="react-confirm-alert-button-group justify-content-end">
                  <button onClick={() => { resolve(); onClose(); }}>{yes}</button>
                  <button onClick={() => { reject(); onClose(); }}>{no}</button>
                </div>
              </div>
            </div>
          )
        }
      })
    })
  }

}
