import React from 'react'
import { switchChain } from '@wagmi/core';
import { Accordion } from 'react-bootstrap';
import cryllexSvg from "./../../../../assets/images/crillex.svg"
import { STELLAR_PLATFORM_TYPE } from '../../../../Constants/MainKeys';

export default function NetworksAndCoins({ chains, setSelectedToken, selectedChain, selectedToken, isLoading, selectedAccordionKeys, setSelectedAccordionKeys, setNetwork }) {

  return (
    <Accordion.Item
      eventKey={1}

    >
      <Accordion.Header
        className={isLoading ? "disabled-accordion" : ""}
        onClick={() => {
          if (selectedAccordionKeys.find(key => +key === 2)) {
            setSelectedAccordionKeys(selectedAccordionKeys.filter(key => +key !== 2))
          } else {
            setSelectedAccordionKeys([...selectedAccordionKeys, 2])
          }
        }}
      >
        <img src={cryllexSvg} alt="/" />
        Select Network & Token
      </Accordion.Header>
      <Accordion.Body>
        <div className="bg-white shadow p-3 text-dark" style={{ borderRadius: "10px" }}>
          <div>{
            chains && chains.length ?
              <div>
                <div>
                  <p><b>Networks</b></p>
                  <ul className="cx-accordion-body-list flex-wrap" id="crypto-block">
                    {
                      chains.map(item => {
                        return <li
                          key={item.id}
                          title={item.chainName}
                          style={{ cursor: "pointer" }}
                          className={`cx-accordion-list-item px-4 pt-3 mb-3 ${selectedChain && selectedChain.id === item.id ? "selected-item" : ""} ${item.platformType === STELLAR_PLATFORM_TYPE ? "disabled" : ""}`}
                          onClick={() => {
                            setNetwork(item);
                          }}
                        >
                          <img src={item.chainLogo} alt="logo" className='rounded' />
                          <div className="cx-accordion-list-item-name">
                            {item.chainSlug}
                          </div>
                        </li>
                      })
                    }
                  </ul>

                </div>
                {
                  selectedChain && selectedChain.tokens && selectedChain.tokens.length ?
                    <div>
                      <hr />
                      <p><b>Tokens</b></p>
                      <ul className="cx-accordion-body-list" id="crypto-block">
                        {
                          selectedChain.tokens.map(item => {
                            return <li
                              title={item.name}
                              style={{ cursor: "pointer" }}
                              key={item.id}
                              className={`cx-accordion-list-item pt-3 ${selectedToken && selectedToken.id === item.id ? "selected-item" : ""}`}
                              onClick={() => setSelectedToken(item)}
                            >
                              <img src={item.logo} alt="logo" className='rounded' />
                              <div className="cx-accordion-list-item-name" >
                                {item?.slug}
                              </div>
                            </li>
                          })
                        }
                      </ul>
                    </div>
                    : null
                }
              </div>
              : null
          }</div>
        </div>
      </Accordion.Body>
    </Accordion.Item>



  )
}
