import { combineReducers, configureStore } from "@reduxjs/toolkit";
import mainReducer from "./Reducers/mainReducer"
import spinnersReducer from "./Reducers/spinnersReducer"
import userReducer from "./Reducers/userReducer"
import blackListReducer from "./Reducers/blackListReducer"

const rootReducer = combineReducers({
  spinners: spinnersReducer,
  main: mainReducer,
  user: userReducer,
  blackList: blackListReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
