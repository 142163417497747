// SolanaContext.js
import React, { createContext, useContext } from 'react';

const SolanaContext = createContext(null);

export const useSolana = () => {
  return useContext(SolanaContext);
};

export const SolanaProvider = ({ endpoint, children }) => {
  return (
    <SolanaContext.Provider value={endpoint}>
      {children}
    </SolanaContext.Provider>
  );
};
