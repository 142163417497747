import 'react-phone-input-2/lib/style.css'
import React, { memo, useState } from 'react'
import ReactSelectOption from '../../../../Components/SelectOptions/ReactSelectOption';
import PhoneInput from 'react-phone-input-2'
import { Accordion, Button } from 'react-bootstrap';
import ApiService from '../../../../Services/ApiService';
import AlertService from '../../../../Services/AlertService';
import MainService from '../../../../Services/MainService';
// import { useAccount } from 'wagmi';
import cryllexSvg from "./../../../../assets/images/crillex.svg"

const KycVerification = memo(({
  isLoading,
  setIsLoading,
  countries,
  isTransactionValidated,
  onAccordionChange,
  getFail,
  paymentData,
  selectedAccordionKeys,
  setSelectedAccordionKeys
}) => {

  // const { isConnected } = useAccount();
  const [isConnected, setIsConnected] = useState(false);
  const [isInvalidFirstName, setIsInvalidFirstName] = useState(false);
  const [isInvalidLastName, setIsInvalidLastName] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [isShowLoader, setIsShowLoader] = useState(false);
  const [isKycSent, setIsKycSent] = useState(false);
  const [values, setValues] = useState({
    residentialAddress: "",
    firstName: "",
    lastName: "",
    email: "",
    selectedCountryId: null,
  })


  const onChange = (event, fieldName) => {
    setValues(values => ({ ...values, [fieldName]: event.target.value }))
  }

  const onPhoneNumberChange = (event, fieldName) => {
    setValues(values => ({ ...values, [fieldName]: event }))
  };

  const onSelectCountry = (selectedItemId, fieldName) => {
    if (!selectedItemId) { return false };
    setValues(values => ({ ...values, [fieldName]: selectedItemId }))
  }

  const checkFieldValidation = (field, fieldName) => {
    const isValid = MainService.isValidField(field, fieldName);
    switch (fieldName) {
      case "firstName":
        checkIsinvalidFieldName(isValid, "isInvalidFirstName")
        break;
      case "lastName":
        checkIsinvalidFieldName(isValid, "isInvalidLastName")
        break;
      case "email":
        checkIsinvalidFieldName(isValid, "isInvalidEmail")
        break;
      default:
        break;
    }
  }

  const checkIsinvalidFieldName = (isValid, name) => {
    switch (name) {
      case "isInvalidFirstName":
        setIsInvalidFirstName(!isValid);
        break;
      case "isInvalidLastName":
        setIsInvalidLastName(!isValid);
        break;
      case "isInvalidEmail":
        setIsInvalidEmail(!isValid);
        break;
      default:
        break;
    }
  }


  const sendKycData = () => {
    setIsLoading(true);
    setIsShowLoader(true);
    const data = {
      phone: values.phoneNumber,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      residentialAddress: values.residentialAddress,
      country: values.selectedCountryId,
    }
    ApiService.sendKycData(data).then(() => {
      AlertService.alert("success", "The verification code has been sent.");
      onAccordionChange(3, 4);
      setIsKycSent(true);
    }).catch(error => getFail(error)).finally(() => {
      setIsShowLoader(false);
    });
  }

  return (


    <Accordion.Item
      eventKey={3}
      // className={!isConnected ? "pointer-events-none" : ""}
    >
      <Accordion.Header
        className={isLoading ? "disabled-accordion" : ""}
        onClick={() => {
          if (document.getElementById("firstName")) {
            setTimeout(() => {
              document.getElementById("firstName")?.focus();
            }, 500);
          };
          if (selectedAccordionKeys.find(key => +key === 3)) {
            setSelectedAccordionKeys(selectedAccordionKeys.filter(key => +key !== 3))
          } else {
            setSelectedAccordionKeys([...selectedAccordionKeys, 3])
          }
        }}
      >
        <img src={cryllexSvg} alt="/" />
        {
          isTransactionValidated ? "KYC verified" : "KYC verification"
        }
      </Accordion.Header>
      <Accordion.Body>
        <div className="bg-white shadow p-3 text-dark" style={{ borderRadius: "10px" }}>
          <div id="kycVerification">
            <p>Please enter your valid credentials to continue. <br /><small>All fields are required.</small></p>
            <div className="d-lg-flex form-group">
              <input
                id="firstName"
                type="text"
                className={`
              form-control ps-3 mb-1 me-0 me-lg-1
              ${isLoading ? "disabled" : ""}
              ${isInvalidFirstName ? "invalid-field" : values.firstName.trim().length ? "valid-field" : ""}
            `}
                placeholder="First name*"
                value={values.firstName || ""}
                required
                onChange={(event) => onChange(event, "firstName")}
                onBlur={() => checkFieldValidation(values.firstName, "firstName")}
              />
              <input
                id="lastName"
                type="text"
                className={`
              form-control ps-3 mb-1 ms-0
              ${isLoading ? "disabled" : ""}
              ${isInvalidLastName ? "invalid-field" : values.lastName.trim().length ? "valid-field" : ""}
            `}
                placeholder="Last name*"
                value={values.lastName || ""}
                required
                onChange={(event) => onChange(event, "lastName")}
                onBlur={() => checkFieldValidation(values.lastName, "lastName")}
              />
            </div>
            <div className="mb-1">
              <input
                id="email"
                type="email"
                className={`
              form-control ps-3
              ${isLoading ? "disabled" : ""}
               ${isInvalidEmail ? "invalid-field" : values.email.trim().length ? "valid-field" : ""}
            `}
                placeholder="Email*"
                value={values.email || ""}
                onChange={(event) => onChange(event, "email")}
                onBlur={() => checkFieldValidation(values.email, "email")}
              />
            </div>
            {
              countries && countries.length ?
                <div className="mb-1" style={{ paddingRight: "1px", paddingLeft: "1px" }}>
                  <ReactSelectOption
                    value={values.selectedCountryId}
                    className={`vnd--select ${values.selectedCountryId ? "valid-field" : ""} ${isLoading ? "disabled" : ""}`}
                    isSearchable={true}
                    placeholder="Select your country*"
                    selectedValue={(() => {
                      const selectedValue = { ...countries.find(data => data.id === values.selectedCountryId) };
                      if (Object.keys(selectedValue).length !== 0) {
                        selectedValue.label = selectedValue.name;
                        selectedValue.value = selectedValue.id;
                        return selectedValue;
                      }
                    })()}
                    items={countries.map(data => ({ label: data.name, value: data.id }))}
                    onChange={(item) => onSelectCountry(item.value, "selectedCountryId")}
                  />
                </div>
                : null
            }
            <div className="mb-1">
              <input
                id="residentialAddress"
                type="text"
                className={`
             form-control ps-3
             ${values.residentialAddress.trim().length ? "valid-field" : ""}
             ${isLoading ? "disabled" : ""}
            `}
                placeholder="Residential address*"
                value={values.residentialAddress}
                required
                onChange={(event) => onChange(event, "residentialAddress")}
              />
            </div>
            <div className="cx-phone-number-block mb-1">
              {
                countries && countries.length ?
                  <PhoneInput
                    country={values.selectedCountryId ? countries.find(item => item.id === values.selectedCountryId)?.isoCode2.toLowerCase() : ""}
                    value={values.phoneNumber}
                    className={`custom-phone-number-input-block ${isLoading ? " disabled" : ""}`}
                    onChange={(event) => onPhoneNumberChange(event, "phoneNumber")}
                  />
                  : null
              }
            </div>
            <div className="d-lg-flex form-group justify-content-end">
              <Button
                id="send_kyc"
                variant='primary'
                className={`
                mt-2 mx-0 px-4
                ${!values.phoneNumber ||
                    isInvalidFirstName ||
                    isInvalidLastName ||
                    isInvalidEmail ||
                    isLoading ||
                    isShowLoader ||
                    !values.selectedCountryId ||
                    !values.firstName.trim().length ||
                    !values.lastName.trim().length ||
                    !values.email.trim().length ||
                    !values.residentialAddress.trim().length ||
                    isTransactionValidated || isKycSent ?
                    "disabled" :
                    ""}`
                }
                onClick={sendKycData}
              >
                {
                  isShowLoader ? "Loading..." : "Send"
                }
              </Button>
            </div>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>




  )
})

export default KycVerification;