import { createSlice } from "@reduxjs/toolkit";

const spinnersSlice = createSlice({
	name: "spinner",
	initialState: {
		pageSpinners: [],
	},
	reducers: {
		addPageSpinner: (state, { payload }) => {
			state.pageSpinners = [...state.pageSpinners, payload]
		},
		removePageSpinner: (state, { payload }) => {
			state.pageSpinners = state.pageSpinners.filter(data => data !== payload)
		},
		
	},
});

export const {
	addPageSpinner,
	removePageSpinner,
} = spinnersSlice.actions;

export default spinnersSlice.reducer;
