import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../modules/Footer";
import { ToastContainer } from 'react-toastify';
import AuthModal from "../Components/Auth/AuthModal";
import { useSelector } from "react-redux";
import PageSpinner from "../Components/Spinners/pageSpinner";
import 'react-toastify/dist/ReactToastify.css';

function Layaut(props) {

  const { pageSpinners } = useSelector(state => state.spinners);

  return (
    <div>

      <Outlet />
      <Footer />
      <ToastContainer theme="dark" />
      <PageSpinner spinner={pageSpinners} />
    </div>
  );
}

export default Layaut;