import axios from 'axios';
import store from "../Store/index.js"
import AlertService from './AlertService.js';
import { setUserData, setUserToken } from '../Store/Reducers/userReducer.js';
import ApiService from './ApiService.js';

const api = axios.create({
  baseURL: process.env.REACT_APP_BLACKLIST_API_URL_KEY || "",
  withCredentials: true,
});

api.interceptors.request.use(async config => {
  config.headers = {
    'Authorization': localStorage.getItem("userToken") ? localStorage.getItem("userToken") : "",
    'token': localStorage.getItem("token") ? localStorage.getItem("token") : {},
    // 'Content-Type': 'application/json; charset=utf-8;',
    language: "en",
  };

  return config;
});

api.interceptors.response.use(response => {
  if (response.data) return response.data;
}, error => {
  if (error.response) {
    if (error.response.status === 404) { // Status Code: 404
      return Promise.reject("404 Not Found");
    } else if (error.response.status === 401) {
      const refreshToken = localStorage.getItem("refreshToken");
      const user = localStorage.getItem("user");
      const token = localStorage.getItem("userToken");
      if (!refreshToken || !user || !token) {
        logout();
      } else {
        getUserDataByRefreshToken(refreshToken);
      }
    } else if (error.response.status === 500) { // Status Code: 500
      return Promise.reject("500 Internal Server Error");
    }
    if (typeof error.response.data === "object") {
      const currentError = { ...error.response.data };
      const errors = currentError.errors?.Error;
      let errorMessages = [];
      if (errors && errors.length > 0) {
        errors.forEach((error) => {
          const errorParams = error.params;
          if (errorParams) {
            let errorMsg = `${errorParams?.Description} ${errorParams?.ErrorNumber ? `(${errorParams?.ErrorNumber})` : ""}`
            errorMessages.push(errorMsg);
          }
        });
      } else {
        return Promise.reject("Unrecognized error");
      }
      if (errorMessages.length) {
        showError(errorMessages)
        return Promise.reject();
      } else {
        return Promise.reject("Unrecognized error");
      }
    } else {
      return Promise.reject("Unrecognized error");
    }

  } else {
    return Promise.reject("Unrecognized error");
  }
});

const logout = () => {
  store.dispatch(setUserData(null));
  store.dispatch(setUserToken(null));
}

const getUserDataByRefreshToken = (refreshToken) => {
  const formData = new FormData();
  formData.append(`refreshToken`, refreshToken);
  ApiService.getUserDataByRefreshToken(formData).then(response => {
    if (response.data && response.data.accessToken) {
      const data = { ...response.data };
      store.dispatch(setUserToken(data));
    } else {
      logout();
    }
  }).catch(error => AlertService.alert("error", error));
}

const showError = (errorMessages) => {
  const filteredStrings = errorMessages.filter(item => isNaN(item));
  filteredStrings.forEach(error => {
    AlertService.alert("error", error)
  });
}

class BlackListApiService {

  static checkBlackList(data) {
    return api.post(`/api/BlackList/Check?val=${data}`)
  };

}

export default BlackListApiService;
