import Logo from '../assets/images/main-logo.svg'
function TopLogo() {
    return (
        <div className="container">
            <header className="cx--header">
                <div className="cx--logo-wrapper">
                    <img src={Logo} alt="logo" />
                </div>
            </header>
        </div>
    );
}

export default TopLogo;