import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { IoMdClose } from "react-icons/io";


export default function BlackListModal({ show = false, handleClose = () => { }, title = "", description = "" }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className='blacklist-modal'
      backdrop="static"
      // keyboard={false}
      centered
    >
      <Modal.Header
        style={{ border: "none" }}
      >
        <Modal.Title>{title}</Modal.Title>
        <div className='close-btn'>
          <IoMdClose size={25} onClick={handleClose} style={{ cursor: "pointer" }} />
        </div>
      </Modal.Header>
      <Modal.Body>
        {description}
      </Modal.Body>
      <Modal.Footer style={{ border: "none" }}>
        <Button variant="light" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
