import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsShowAuthModal } from '../../Store/Reducers/userReducer';
import { Modal } from 'react-bootstrap';
import RegistrationModal from './RegistrationModal';
import LoginModal from './LoginModal';
import ForgotPassword from './ForgotPassword';
import RestorePassword from './RestorePassword';
import { useLocation, useSearchParams, useNavigate, useParams } from 'react-router-dom';


export default function AuthModal(props) {

  const { token } = useParams();
  const [searchParams] = useSearchParams();
  const { isShowBlackListModal } = useSelector(state => state.blackList);
  const page = searchParams.get('page');
  const guid = searchParams.get('guid');

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { isShowAuthModal, user } = useSelector(state => state.user);
  const [modalType, setModalType] = useState();

  useEffect(() => {
    if (user) {
      dispatch(setIsShowAuthModal(false));
    } else {
      dispatch(setIsShowAuthModal(true));
    }
  }, [user])

  useEffect(() => {
    if (!page) {
      if (guid) {
        navigate(`/${token || ""}?page=${1}&guid=${guid}`)
      } else {
        navigate(`/${token || ""}?page=${1}`)
      }
    } else {
      if (guid) {
        navigate(`/${token || ""}?page=${page}&guid=${guid}`)
      } else {
        navigate(`/${token || ""}?page=${page}`)
      }
    }
    setModalType(page ? +page : 1)
  }, [token, page])

  if (isShowBlackListModal) {
    return null;
  }

  return (
    <div>
      <Modal
        show={isShowAuthModal}
        onHide={() => { }}
        centered
        size='md'
      >
        {
          (() => {
            switch (modalType) {
              case 1:
                return <LoginModal />;
              case 2:
                return <RegistrationModal />;
              case 3:
                return <ForgotPassword />;
              case 4:
                return <RestorePassword />;

              default:
                break;
            }
          })()
        }
      </Modal>

    </div>
  )
}





