import React, { useEffect, useState, memo } from "react"
import { Accordion, Button } from 'react-bootstrap'
import MainPrice from "./components/MainPrice/MainPrice"
import WalletConnectPayBtn from "./components/PayBtn/WalletConnectPayBtn"
import ApiService from '../../Services/ApiService'
import AlertService from '../../Services/AlertService'
import MainService from '../../Services/MainService'
import { useSelector } from 'react-redux'
import TransactionWaiting from "../TransactionWaiting"
import TopLogo from "../TopLogo"
import $ from "jquery"
import * as signalR from '@microsoft/signalr'
import KycVerification from "./components/KycVerification/KycVerification"
import OTP from "./components/OTP/OTP"
import { useDebounce } from 'use-debounce'
import NetworksAndCoins from "./components/NetworksAndCoins/NetworksAndCoins"
import CountdownTimer from "./components/CountdownTimer/CountdownTimer"
import WalletConnectWalletConfig from "./components/WalletConfigs/WalletConnectWalletConfig"
import SolanaWalletConfig from "./components/WalletConfigs/SolanaWalletConfig"
import StellarWalletConfig from "./components/WalletConfigs/StellarWalletConfig"
import TronWalletConfig from "./components/WalletConfigs/TronWalletConfig"
import { AVALANCHE_PLATFORM_TYPE, ETHEREUM_PLATFORM_TYPE, SOLANA_PLATFORM_TYPE, STELLAR_PLATFORM_TYPE, TRON_PLATFORM_TYPE } from "../../Constants/MainKeys"
import cryllexSvg from "./../../assets/images/crillex.svg"
import SolanaPayBtn from "./components/PayBtn/SolanaPayBtn"
import TronPayBtn from "./components/PayBtn/TronPayBtn"
import StellarPayBtn from "./components/PayBtn/StellarPayBtn"
import BlackListComponent from "../../Components/BlackList/BlackListComponent"

const Main = memo(({
    connection,
    signal_update,
    chains,
    config,
    setSelectedToken,
    selectedToken,
    selectedChain,
    setNetwork,
    setCrypto,
    countries
}) => {

    var isPaymentCancelled = false;
    const { token } = useSelector(state => state.main);
    const [isLoading, setIsLoading] = useState(false);
    const [showOTPloader, setShowOTPloader] = useState(false);
    const [price, setPrice] = useState(null);
    const [tick, setTick] = useState(null);
    const [activeKeys, setActiveKeys] = useState([1]);
    const [organizationData, setOrganizationData] = useState(null);
    const [isTransactionValidated, setIsTransactionValidated] = useState(false);
    const [isShowTransactionWaitingModal, setIsShowTransactionWaitingModal] = useState(false);
    const [kyc_numbers, set_kyc_numbers] = useState("");
    const [debounce_kyc_numbers] = useDebounce(kyc_numbers, 500);
    const [selectedAccordionKeys, setSelectedAccordionKeys] = useState([]);
    const [paymentData, setPaymentData] = useState(null);
    const [to, setTo] = useState(null);
    const [allBalance, setAllBalance] = useState(null);
    const [amount, setAmount] = useState(0);
    const [address, setAddress] = useState("");

    useEffect(() => {
        if (debounce_kyc_numbers.length === 8) {
            validateKyc(debounce_kyc_numbers);
        }
    }, [debounce_kyc_numbers])

    useEffect(() => {
        if (connection && connection.state === signalR.HubConnectionState.Connected) {
            init();
            connection.on("updatecrypto", (id, _price) => {
                try {
                    if (_price) {
                        setPrice(JSON.parse(_price).price)
                        setTick(JSON.parse(_price).tick)
                    }
                } catch (error) {
                    AlertService.alert("error", error)
                }
            });
        }

    }, [connection])

    useEffect(() => {
        if (address) {
            getAccountBalance(address);
        }
    }, [address])

    const getAccountBalance = async (address) => {
        if (!address || !selectedChain) { return false; }
        ApiService.getAccountBalance(address, selectedChain.id).then(response => {
            if (response && response.data) {
                setAllBalance(response.data)
            }
        }).catch(error => getFail(error))
    }

    const init = () => {
        try {
            setTimeout(() => {
                verifyIsKycAlreadyDone();
            }, 500);
        } catch (e) {
            setTimeout(() => { init(); }, 1000);
        }
    }

    const verifyIsKycAlreadyDone = () => {
        ApiService.verifyIsKycAlreadyDone().then(response => {
            if (response && response.data) setIsTransactionValidated(true);
        }).catch(error => getFail(error));
    }

    useEffect(() => {
        analyzePaymentData();
    }, [selectedChain]);


    const analyzePaymentData = (_loadOrgData) => {
        if (_loadOrgData == undefined) _loadOrgData = true;
        if (!token) { return false; }
        ApiService.analyzePaymentData(token).then(response => {
            if (response && response.data) {
                const data = { ...response.data };
                if (data.cancelUrl && isPaymentCancelled) {
                    window.location.href = data.cancelUrl;
                }
                successFunc(data, _loadOrgData)
            };
        }).catch(error => getFail(error))
    }

    const successFunc = (data, _loadOrgData) => {
        setPaymentData(null);
        setIsShowTransactionWaitingModal(false);
        setTimeout(() => {
            setPaymentData(data);
            data.cryptoAmount && setPrice(data.cryptoAmount);
            if (data.paymentRequested) {
                setIsShowTransactionWaitingModal(true);
                checkPaymentStatus();
            }
            if (_loadOrgData) {
                loadOrgData(data.organizationId);
            }
        }, 500);
    };

    const checkPaymentStatus = () => {
        try {
            const paymentTime = paymentData?.paymentRequestedDatetime;
            const localPaymentTime = MainService.convertUTCDateToLocalDate(new Date(paymentTime));
            const passedSeconds = Math.floor((Date.now() - localPaymentTime.getTime()) / 1000);

            if (passedSeconds > 300 && isShowTransactionWaitingModal && paymentData.status === 0) {
                cancelPayment(false);
            }
        } catch (error) {
            AlertService.alert("error", error);
            return;
        }

        if (paymentData && typeof paymentData.status === "number") {
            setTimeout(async () => {
                try {
                    const response = await ApiService.analyzePaymentData(token);
                    if (response && response.data) {
                        if (JSON.stringify(response.data) !== JSON.stringify(paymentData)) {
                            setPaymentData({ ...response.data });
                        }
                    }
                } catch (error) {
                    AlertService.alert("error", error);
                } finally {
                    checkPaymentStatus();
                }
            }, 1000);
        }
    }

    const loadOrgData = (organizationId) => {
        setIsLoading(true);
        ApiService.loadOrgData(organizationId).then(response => {
            if (response && response.data) {
                setOrganizationData(response.data.organizationDetail)
            }
            setIsLoading(false)
        }).catch(error => getFail(error));
    }

    const validateKyc = (data) => {
        setShowOTPloader(true);
        ApiService.validateKyc({ Code: data.toString() }).then(response => {
            if (response && response.data) {
                setIsTransactionValidated(true);
                verifyIsKycAlreadyDone();
                if (!selectedAccordionKeys.find(key => +key === 4)) {
                    setActiveKeys(activeKeys.filter(key => +key !== 4));
                }
            } else {
                AlertService.alert("error", "OTP code is invalid");
            }
            setTimeout(() => {
                setShowOTPloader(false);
                clearOTPInputs();
            }, 1000);
        }).catch(error => getFail(error))
    }

    const pay = (cb) => {

        if (!selectedChain) {
            AlertService.alert("warning", "Please select any network.");
            return false;
        }

        if (!selectedToken) {
            AlertService.alert("warning", "Please select any token.");
            return false;
        }

        ApiService.pay().then(resp => {
            if (resp && resp.data) {
                const data = { ...resp.data }
                signal_update();
                setTo(data.account);
                setAmount(data.amount)
                analyzePaymentData(false);
                setTimeout(() => {
                    cb && cb();
                }, 500);
            }
        }).catch(error => getFail(error));
    }

    const paySuccess = (txHash = "", chainId = null,) => {
        if (!txHash) { return false; }
        let data = {
            txHash,
            chainId
        }
        ApiService.paySuccess(data).then(() => {
            analyzePaymentData(false);
            signal_update();
        }).catch(error => getFail(error))
    }

    const payError = (err_code, err_message) => {
        ApiService.payError({ Code: `${err_code}`, Message: err_message }).then(() => {
            analyzePaymentData(false);
            signal_update();
        }).catch(error => getFail(error))
    }

    const cancelPayment = (showConfirm = true) => {
        const cancel = () => {
            ApiService.cancelPayment(token).then(() => {
                isPaymentCancelled = true;
                analyzePaymentData(false);
                setIsLoading(false);
                setIsTransactionValidated(false);
                setShowOTPloader(false);
                clearOTPInputs();
            });
        };

        if (showConfirm) {
            AlertService.alertConfirm(
                `Payment cancellation`,
                "Are you sure you want to cancel this payment?",
                "Yes",
                "No"
            ).then(() => {
                cancel();
            });
        } else {
            cancel();
        }
    };

    const getFail = (error) => {
        error && AlertService.alert("error", error);
        isPaymentCancelled = false;
        setIsLoading(false);
        clearOTPInputs();
        setTimeout(() => {
            setShowOTPloader(false);
        }, 1000);
    }

    const clearOTPInputs = () => {
        set_kyc_numbers("");
        if ($(document.querySelectorAll('.kyc-number')[0])) {
            $(document.querySelectorAll('.kyc-number')[0]).focus();
        };
    }

    const onAccordionChange = (closedId, openId) => {
        let activeKeysCopy = [...activeKeys];
        activeKeysCopy = activeKeysCopy.filter(item => item !== closedId);
        if (!activeKeysCopy.find(el => el === openId)) { activeKeysCopy.push(openId) }
        setTimeout(() => {
            setActiveKeys(activeKeysCopy);
        }, 500);

        if (closedId === 3) {
            if (document.getElementById("kyc_0")) {
                setTimeout(() => {
                    document.getElementById("kyc_0").focus();
                }, 500);
            };
        }
    }

    const setPayButtonComponent = () => {
        const commonProps = {
            price,
            paymentData,
            isTransactionValidated,
            pay,
            selectedChain,
            selectedToken,
            amount,
            paySuccess,
            payError,
            to,
        };
        switch (selectedChain?.platformType) {
            case AVALANCHE_PLATFORM_TYPE:
            case ETHEREUM_PLATFORM_TYPE:
                return <WalletConnectPayBtn {...commonProps} />
            case SOLANA_PLATFORM_TYPE:
                return <SolanaPayBtn {...commonProps} />
            case STELLAR_PLATFORM_TYPE:
                return <StellarPayBtn  {...commonProps} />
            case TRON_PLATFORM_TYPE:
                return <TronPayBtn {...commonProps} />
            default:
                return <div></div>;
        }
    };

    const setWalletConfigComponent = () => {
        const commonProps = {
            isLoading,
            selectedAccordionKeys,
            setSelectedAccordionKeys,
            amount,
            allBalance,
            selectedChain,
            selectedToken,
            setAddress,
        };

        switch (selectedChain?.platformType) {
            case AVALANCHE_PLATFORM_TYPE:
            case ETHEREUM_PLATFORM_TYPE:
                return <WalletConnectWalletConfig {...commonProps} />
            case SOLANA_PLATFORM_TYPE:
                return <SolanaWalletConfig {...commonProps} />
            case STELLAR_PLATFORM_TYPE:
                return <StellarWalletConfig {...commonProps} />
            case TRON_PLATFORM_TYPE:
                return <TronWalletConfig {...commonProps} />

            default:
                return <Accordion.Item
                    eventKey={2}
                >
                    <Accordion.Header
                        className={`disabled-accordion`}
                    >
                        <img src={cryllexSvg} alt="/" />
                        Connect Your Wallet
                    </Accordion.Header>
                </Accordion.Item>
        }
    };


    return (
        // connection && connection.state === signalR.HubConnectionState.Connected ?
        <>
            {/* <CountdownTimer initialSeconds={5} /> */}
            <TopLogo />
            {/* <BlackListComponent
                paymentData={paymentData}
            /> */}
            <div className="container scrollbar" id="main">
                <div className={`cx-window-container`}>
                    <div className="cx-window-wrapper">
                        <TransactionWaiting
                            connection={connection}
                            paymentData={paymentData}
                            cancelPayment={cancelPayment}
                            isShowTransactionWaitingModal={isShowTransactionWaitingModal}
                        />
                        <MainPrice
                            paymentData={paymentData}
                            tick={tick}
                            price={price}
                            organizationData={organizationData}
                            selectedChain={selectedChain}
                            selectedToken={selectedToken}
                            selectedWallet={null}
                            selectedCrypto={null}
                            chain={null}
                        />
                        <div className={`cx-window-right-side ${organizationData && paymentData ? "" : "disabledd"}`}>
                            <div className="cx-window-body-container">
                                <div className={`accordion`} id="cryllexAccordion">
                                    <Accordion
                                        alwaysOpen
                                        activeKey={activeKeys}
                                        onSelect={(event) => {
                                            setActiveKeys(event);
                                        }}
                                    >


                                        {/* 1 Accordion */}
                                        <NetworksAndCoins
                                            chains={chains}
                                            selectedToken={selectedToken}
                                            setSelectedToken={setSelectedToken}
                                            config={config}
                                            selectedChain={selectedChain}
                                            isLoading={isLoading}
                                            selectedAccordionKeys={selectedAccordionKeys}
                                            setSelectedAccordionKeys={setSelectedAccordionKeys}
                                            setNetwork={setNetwork}
                                            setCrypto={setCrypto}
                                        />


                                        {/* 2 Accordion */}
                                        {setWalletConfigComponent()}


                                        {/* 3 Accordion */}
                                        <KycVerification
                                            isLoading={isLoading}
                                            setIsLoading={setIsLoading}
                                            isTransactionValidated={isTransactionValidated}
                                            countries={countries}
                                            onAccordionChange={onAccordionChange}
                                            paymentData={paymentData}
                                            getFail={getFail}
                                            selectedAccordionKeys={selectedAccordionKeys}
                                            setSelectedAccordionKeys={setSelectedAccordionKeys}
                                        />


                                        {/* 4 Accordion */}
                                        <OTP
                                            isLoading={isLoading}
                                            isTransactionValidated={isTransactionValidated}
                                            kyc_numbers={kyc_numbers}
                                            showOTPloader={showOTPloader}
                                            set_kyc_numbers={set_kyc_numbers}
                                            selectedAccordionKeys={selectedAccordionKeys}
                                            setSelectedAccordionKeys={setSelectedAccordionKeys}
                                        />


                                    </Accordion>
                                </div>
                                <hr className="text-white mt-0" />

                                {/* Pay btn */}
                                {setPayButtonComponent()}

                                <div className="m-5 cx-cancel-btn">
                                    <button id="cancelbtn" data-href="/delete.php?id=23" data-toggle="modal"
                                        data-target="#confirm-delete" onClick={cancelPayment}>Cancel payment</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>

        // : null
    );
})


export default Main;
