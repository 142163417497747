import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import MainService from "../../Services/MainService";
import { FaRegEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { ImSpinner3 } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import GoogleLoginButton from "./GoogleAuth";
import ApiService from "../../Services/ApiService";
import AlertService from "../../Services/AlertService";
import { IdentityService } from "../../Services/IdentityService";
import { setUserData, setUserToken } from "../../Store/Reducers/userReducer";
import PhoneInput from "react-phone-input-2";


const RegistrationModal = () => {

  const dispatch = useDispatch();
  const { token } = useSelector(state => state.main);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [values, setValues] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    password: "",
    isAgree: false,
  })

  const onSubmit = (event) => {
    event.preventDefault();
    if (!values.email.trim().length || !values.password.trim().length || !values.fullName.trim().length || !values.phoneNumber) {
      AlertService.alert("warning", "All fields are required")
      return false;
    }
    setIsLoading(true);
    let data = {
      email: values.email,
      password: window.btoa(values.password),
      confirmPassword: window.btoa(values.password),
      email: values.email,
      phoneNumber: values.phoneNumber,
    }
    ApiService.userRegistration(data).then(() => {
      login();
    }).catch(error => AlertService.alert("error", error)).finally(() => {
      setIsLoading(false);
    })
  }

  const getCurrentUser = () => {
    ApiService.getCurrentUser().then(response => {
      if (response && response.data) {
        dispatch(setUserData(response.data));
      }
    }).catch(error => AlertService.alert("error", error)).finally(() => {
      setIsLoading(false);
    })
  }

  const login = () => {
    let data = {
      username: values.email,
      password: values.password,
    }
    IdentityService.login(data).then(response => {
      if (response && response.data) {
        dispatch(setUserToken(response.data));
        setTimeout(() => {
          getCurrentUser();
        }, 100);
      }
    }).catch(error => AlertService.alert("error", error)).finally(() => {
      setIsLoading(false);
    })
  }

  const onPhoneNumberChange = (event, fieldName) => {
    setValues(values => ({ ...values, [fieldName]: event }))
  };



  return (
    <React.Fragment>
      <Modal.Header>
        <Modal.Title>
          Sign Up
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>

          <div>
            <Form.Group className="mb-3" controlId="fullName">
              <Form.Label>Full Name or Business Name*</Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g. John Smith"
                value={values.fullName}
                required
                onChange={(event) => MainService.onChange({ event, fieldName: "fullName", cb: setValues, maxLength: 120 })}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email Address*</Form.Label>
              <Form.Control
                type="email"
                placeholder="e.g. example@gmail.com"
                value={values.email}
                required
                onChange={(event) => MainService.onChange({ event, fieldName: "email", cb: setValues, maxLength: 120 })}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="phoneNumber">
              <Form.Label>Phone Number*</Form.Label>
              <PhoneInput
                country={"hk"}
                value={values.phoneNumber}
                className={`custom-phone-number-input-block ${isLoading ? " disabled" : ""}`}
                onChange={(event) => onPhoneNumberChange(event, "phoneNumber")}
              />
            </Form.Group>
            <Form.Group className="position-relative mb-3" controlId="password">
              <Form.Label>Password*</Form.Label>
              <Form.Control
                type={`${isShowPassword ? "text" : "password"}`}
                placeholder="Enter password"
                required
                value={values.password}
                onChange={(event) => MainService.onChange({ event, fieldName: "password", cb: setValues, maxLength: 120 })}
              />
              <div className='eye-block' onClick={() => setIsShowPassword(!isShowPassword)}>
                {
                  isShowPassword ?
                    <FaRegEye size={18} color='grey' />
                    :
                    <FaEyeSlash size={18} color='grey' />
                }
              </div>
            </Form.Group>
            <div className="d-flex">
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  id="isAgree"
                  type="checkbox"
                  value={values.isAgree}
                  onChange={(event => MainService.onCheckBoxChange({ event, fieldName: "isAgree", cb: setValues }))}
                />
              </Form.Group>
              <label htmlFor="isAgree" className="ms-2 d-flex">
                <span>I agree to the&nbsp;</span>
                <a href="#" className={`${isLoading ? "disabled" : ""}`} >
                  Terms & Conditions
                </a>
                <span>&nbsp;and&nbsp;</span>
                <a href="#" className={`${isLoading ? "disabled" : ""}`} >
                  Privacy Policy.
                </a>
              </label>
            </div>
          </div>
          <div className='my-4 mb-3'>
            <div className='d-flex justify-content-center my-3'>
              {
                isLoading ?
                  <Button type='submit' variant="primary" className='px-4 w-100' disabled>
                    <span className='me-2'>Loading</span>
                    <ImSpinner3 className='rotating' />
                  </Button>
                  :
                  <Button type='submit' variant="primary" className='px-4 w-100'>
                    Sign Up
                  </Button>
              }
            </div>
            {/* <div className="google-auth-block">
              <div className="alternative">
                <div className="line w-100"></div>
                <span>or</span>
                <div className="line w-100"></div>
              </div>
              <div className="d-flex justify-content-center ">
                <GoogleLoginButton />
              </div>
            </div> */}
            <div className='d-flex gap-2 justify-content-center'>
              <span>You already have an account ?</span>
              <Link to={`/${token}?page=${1}`}
                className={`${isLoading ? "disabled" : ""}`}
              >
                Sign In
              </Link>
            </div>
          </div>
        </Form>
      </Modal.Body>

    </React.Fragment>
  )
}

export default RegistrationModal