import React, { useEffect, useState } from 'react';
import BlackListModal from './BlackListModal';
import BlackListApiService from '../../Services/BlackListApiService';
import { useDispatch, useSelector } from 'react-redux';
import { setIsShowBlackListModal } from '../../Store/Reducers/blackListReducer';

const titleMap = {
  0: 'Your Phone Number is Blacklisted',
  1: 'Your Wallet Address is Blacklisted',
  2: 'Your IP Address is Blacklisted',
  3: 'Your Country is Blacklisted',
  4: 'Your Phone Code is Blacklisted',
  5: 'Your City is Blacklisted',
};

export default function BlackListComponent() {
  const dispatch = useDispatch();
  const { isShowBlackListModal } = useSelector(state => state.blackList);

  const [errorMessage, setErrorMessage] = useState('');
  const [errorTitle, setErrorTitle] = useState('Your Contact is Blacklisted');

  const handleClose = () => {
    dispatch(setIsShowBlackListModal(false));
    window.location.href = 'https://example-url.com';
  };

  useEffect(() => {
    // checkBlackList();
  }, []);

  const checkBlackList = () => {
    BlackListApiService.checkBlackList('iran')
      .then(response => {
        console.log(response, 'response');

        if (response.data) {
          const { wallet, country, city, ipaddress } = response.data;

          let blacklistType = null;

          if (wallet) {
            blacklistType = wallet.type;
          } else if (country) {
            blacklistType = country.type;
          } else if (city) {
            blacklistType = city.type;
          } else if (ipaddress) {
            blacklistType = ipaddress.type;
          }

          if (blacklistType !== null) {
            setErrorTitle(titleMap[blacklistType]);
          }
        }

        if (response.errors && response.errors.val && response.errors.val[0] && response.errors.val[0].params && response.errors.val[0].params.val) {
          const errorDetail = response.errors.val[0].params.val;
          setErrorMessage(errorDetail);
          dispatch(setIsShowBlackListModal(true))
        }
      })
      .catch(error => {
        console.log(error, 'error');
        if (error.errors && error.errors.val && error.errors.val[0] && error.errors.val[0].params && error.errors.val[0].params.val) {
          const errorDetail = error.errors.val[0].params.val;
          setErrorMessage(errorDetail);
          dispatch(setIsShowBlackListModal(true))
        }
      });
  };

  return (
    <div>
      <BlackListModal
        show={isShowBlackListModal}
        handleClose={handleClose}
        title={errorTitle}
        description={errorMessage || 'We have found that your contact information is on the blacklist. Please contact us for further information or to resolve this issue.'}
      />
    </div>
  );
}
